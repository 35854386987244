import Component from '../../Component';
import { getCookie, setCookie, lsTest } from '../../helpers/helpers';

class CartModel extends Component {
  constructor() {
    super();
    this.state = {};
  }

  getCart() {
    let cart = getCookie('mtva_cart') || "[]";
    /*
    let cart;
    if (lsTest()) {
      cart = localStorage.getItem('mtva_cart') || "[]";
    } else {
      cart = getCookie('mtva_cart') || "[]";
    }
    */
    cart = JSON.parse(cart);
    return cart;
  }

  setCart(newCart) {
    newCart = this.encodeWebshopProducts(newCart);

    setCookie('mtva_cart', JSON.stringify(newCart), 365);
    /*
    if (lsTest()) {
      localStorage.setItem('mtva_cart', JSON.stringify(newCart));
    } else {
      setCookie('mtva_cart', JSON.stringify(newCart), 365);
    }
    */
  }

  itemInCart(item) {
    let cart = this.getCart();
    let result = cart.find(i => {
      let a = i.id == item.id && i.size == item.size;
      let b = !item.dimensions || item.dimensions == i.dimensions;
      let c = !item.material || item.material == i.material;
      return a && b && c;
    });
    return result;
  }

  idInCart(id) {
    console.log('looking for ' + id)
    let cart = this.getCart();
    return cart.find(i => i.id === id);
  }

  getPrices(cart, callback = false) {
    if (cart && cart.length) {
      $.ajax({
        url: SITE_URL + "api/get_prices",
        method: "POST",
        data: {
          items: cart,
          token: getCookie('MtvaArchivumToken')
        },
        success: response => {
          response = JSON.parse(response);
          console.log(response);
          let filtered = response.filter(item => {
            if (item.price !== false) return item;
          })
          if (filtered.length !== response.length) {
            response = filtered
          }
          if (callback) callback(response);
        },
        error: error => {
          console.log(error);
        }
      })
    } else {
      if (callback) callback([])
    }
  }

  addToCart(item) {
    let cart;
    cart = this.getCart()
    let isPackage = item.id === 'package';
    let isadded = this.itemInCart(item);
    if (!isadded || isPackage) {
      if (item.type === 'photo' && item.size == 'canvas') {
        if (item.material && item.dimensions) {
          cart.push(item);
        }
      } else {
        cart.push(item);
      }

      this.getPrices(cart, result => {
        this.setCart(result);
        this.emit('addedToCart', result);
      })
    } else {
      console.log(isadded, cart)
    }
  }
  
  removeFromCart(id) {
    console.log('removing ' + id)
    let cart;
    cart = this.getCart();
    let index = cart.findIndex(i => i.id == id);
    if (index >= 0) {
      cart.splice(index, 1);
    }
    if (!cart.find(i => ((i.size === 'canvas' || i.size === 'paper') && i.type === 'photo') || i.type === 'shop_product')) {
      let shipment = cart.findIndex(i => i.type === 'shipment');
      let vienna_collection_point = cart.findIndex(i => i.type === "vienna_collection_point");

      if (shipment >= 0) {
        cart.splice(shipment, 1);
      }

      if (vienna_collection_point >= 0) {
        cart.splice(vienna_collection_point, 1);
      }
    }

    let shop_product = true;
    if (!cart.find(i => (i.type === 'shop_product'))) {
      let personal_collection = cart.findIndex(i => i.type === "personal_collection");
      let parcel_point = cart.findIndex(i => i.type === "parcel_point");
      let post_point = cart.findIndex(i => i.type === "post_point");
      let ldap_discount = cart.findIndex(i => i.type === "ldap_discount");

      if (personal_collection >= 0) {
        cart.splice(personal_collection, 1);
      }

      if (parcel_point >= 0) {
        cart.splice(parcel_point, 1);
      }

      if (post_point >= 0) {
        cart.splice(post_point, 1);
      }

      if (ldap_discount >= 0) {
        cart.splice(ldap_discount, 1);
      }

      shop_product = false;
    }

    if (!cart.find(i => ((i.size === 'canvas' || i.size === 'paper') && i.type === 'photo') && shop_product)) {
      let personal_collection = cart.findIndex(i => i.type === "personal_collection");

      if (personal_collection >= 0) {
        cart.splice(personal_collection, 1);
      }
    }
    this.getPrices(cart, result => {
      this.setCart(result);
      this.emit('removedFromCart', result);

    })
  }

  emptyCart() {
    this.setCart([]);
    this.emit('emptiedCart');
  }

  switch(id, new_item) {
    console.log('switching')
    let cart;
    cart = this.getCart();
    let index = cart.findIndex(i => i.id == id);
    if (index >= 0) {
      cart.splice(index, 1, new_item);
    }
    if (!cart.find(i => ((i.size === 'canvas' || i.size === 'paper') && i.type === 'photo') || i.type === 'shop_product')) {
      let shipment = cart.findIndex(i => i.type === 'shipment');
      if (shipment >= 0) {
        cart.splice(shipment, 1);
      }
    }
    this.getPrices(cart, result => {
      this.setCart(result);
      this.emit('switched', result);
    })
  }

  encodeWebshopProducts(items) {
    items?.map((item, key) => {
      if (item?.type === "shop_product") {
        items[key]['name'] = encodeURIComponent(item.name);
        items[key]['img'] = encodeURIComponent(item.img);
        
        item?.attributes?.map((attribute, k) => {
          items[key]['attributes'][k].display = encodeURIComponent(attribute.display);
          items[key]['attributes'][k].value = encodeURIComponent(attribute.value);
        })
      }
    })

    return items;
  }
}

export default CartModel;