import Component from './Component';
import { parseQuery } from './helpers/helpers';

class ModelComponent extends Component {
  constructor() {
    super();
    this.query = parseQuery(window.location.href);
  }
}

export default ModelComponent;