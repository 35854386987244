import ModelComponent from "../../../ModelComponent";

class ShopProduct extends ModelComponent {
  constructor() {
    super();
  }

  getProduct(id) {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_product?id=" + id,
      success: (response) => {
        response = JSON.parse(response);
        if (response.status === 200) {
          this.emit("gotProduct", response.data);
        } else if (response.status === 404) {
          this.emit("renderError404");
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getProductAttributeOptions(groupId) {
    $.ajax({
      method: "GET",
      url:
        SITE_URL +
        "api/webshop/v1/get_product_attribute_options?id_group=" +
        groupId,
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotAttributeOptions", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  async getRequiredAttribtues(id) {
    await $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_group_attributes?id=" + id,
      success: (response) => {
        response = JSON.parse(response);

        this.emit("gotRequriedAttributes", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getRandomProducts() {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_random_products",
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotRandomProducts", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getProductByAttributes(attributes, groupId) {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_product_by_attribtues",
      data: {
        attributes: attributes,
        groupId: groupId,
      },
      success: (response) => {
        response = JSON.parse(response);
        if (response.status === 200) {
          this.emit("gotSelectedProduct", response.data);
        } else if (response.status === 404) {
          this.emit("renderError", response);
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  async getGroupProducts(groupId) {
    await $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_group_products",
      data: {
        id: groupId,
      },
      success: (response) => {
        response = JSON.parse(response);

        this.emit("gotGroupProducts", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getSelectedProduct(id) {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_product",
      data: {
        id: id,
      },
      success: (response) => {
        response = JSON.parse(response);

        this.emit("gotSelectedProduct", response.data);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}

export default ShopProduct;
