
const Secondary_menu = (function () {

  const setupListeners = () => {
    $(".secondary-menu-item span[data-target='tesztTarget']").click((e) => {
      alert("test target clicked");
    });

    $(".secondary-menu-item span[data-target='tesztTarget2']").click((e) => {
      alert("test2 target clicked");

      $('#secondary-nav').collapse('hide');

    });

    $(".extended-sub-menu .toggle-title").click((e) => {
      $('#secondary-extended_nav').collapse('toggle');
    })
  }

  return {
    init: () => {
      setupListeners();
    }
  }

})()

export default Secondary_menu;