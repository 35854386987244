class RotateSpriteImg {
  constructor() {
    this.clickPosition = null;
    this.currentImgIndex = 0;
    this.def3dImgOptions = {
      ext: ".jpg",
      imgCount: 19,
      speed: 10,
    };
    this.container = null;
    this.imgHolder = null;
    this.mouseIcon = null;
    this.product = null;
    this.loader = null;
  }

  set(container, imgHolder, mouseIcon, product, loader, options = null) {
    if (options?.ext) this.def3dImgOptions.ext = options.ext;
    if (options?.imgCount) this.def3dImgOptions.ext = options.imgCount;
    if (options?.speed) this.def3dImgOptions.ext = options.speed;

    this.container = container;
    this.imgHolder = imgHolder;
    this.mouseIcon = mouseIcon;
    this.product = product;
    this.loader = loader;

    this.setEventHandlers();

    if (product.hasSpriteImg) {
      this.loadImg(
        `${SITE_URL}/public/uploads/webshop/${product.id}_sprite_image.jpg`
      ).then(() => {
        imgHolder.css(
          "background",
          `url(${SITE_URL}/public/uploads/webshop/${product.id}_sprite_image.jpg) no-repeat center center `
        );

        container.addClass("loaded");
        this.loader.removeClass("loading");
      });
    }
  }

  setEventHandlers() {
    this.imgHolder.on("mousedown touchstart", (e) => {
      if (e.type === "mousedown") {
        this.clickPosition = e.clientX;
      } else if (e.type === "touchstart") {
        let touch =
          e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
        this.clickPosition = touch.pageX;
      }

      let currentWidth = this.imgHolder.width();
      let imgOffset = currentWidth / this.def3dImgOptions.imgCount;

      $(document).on("mousemove touchmove", (e) => {
        let offset = null;
        let touch = null;

        this.mouseIcon.addClass("clicked");

        if (e.type === "mousemove") {
          offset = e.clientX - this.clickPosition;
        } else if (e.type === "touchmove") {
          touch =
            e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];

          offset = touch.pageX - this.clickPosition;
        }

        if (Math.abs(offset) > this.def3dImgOptions.speed) {
          if (e.type === "mousemove") {
            this.clickPosition = e.clientX;
          } else if (e.type === "touchmove") {
            this.clickPosition = touch.pageX;
          }
          this.currentImgIndex =
            this.currentImgIndex + 1 * (offset / Math.abs(offset));

          if (this.currentImgIndex > this.def3dImgOptions.imgCount - 1) {
            this.currentImgIndex = 0;
          }

          if (this.currentImgIndex < 0)
            this.currentImgIndex = this.def3dImgOptions.imgCount - 1;
        }

        let offsetPercent = this.calculateXoffset(
          this.currentImgIndex * imgOffset,
          currentWidth
        );

        this.imgHolder.css("transform", `translateX(-${offsetPercent}%)`);
      });

      this.imgHolder.on("drag", (e) => {
        return false;
      });

      this.imgHolder.on("dragdrop", (e) => {
        return false;
      });

      this.imgHolder.on("dragstart", (e) => {
        return false;
      });
    });

    $(document).on("mouseup touchend", (e) => {
      $(document).off("mousemove");
      $(document).off("touchmove");
      this.mouseIcon.removeClass("clicked");
    });
  }

  switchProductImg(product) {

    this.container.removeClass("loaded");
    this.product = product;
    this.resetRotation();

    this.loadImg(
      `${SITE_URL}/public/uploads/webshop/${product.id}_sprite_image.jpg`
    ).then(() => {
      this.imgHolder.css(
        "background",
        `url(${SITE_URL}/public/uploads/webshop/${product.id}_sprite_image.jpg) no-repeat center center`
      );

      this.container.addClass("loaded");
      this.loader.removeClass("loading");
    });
  }

  resetRotation() {
    this.clickPosition = null;
    this.currentImgIndex = 0;
    this.imgHolder.css("transform", `none`);
  }

  calculateXoffset(offset, currentWidth) {
    return (100 * offset) / currentWidth;
  }

  loadImg(src) {
    this.loader.addClass("loading");

    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", resolve);
      image.addEventListener("error", reject);
      image.src = src;
    });
  }
}

export default RotateSpriteImg;
