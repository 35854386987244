import Cart from './submodules/globalCart/Cart';
import PageController from './PageController';

class CartController extends PageController {
  constructor() {
    super();
    this.cart = new Cart();
  }
}

export default CartController;