import Cart from './Cart'

const globalCart = (function() {
    return {
      init: () => {
        const cart = new Cart();
        cart.init();
      }
    }
  })();
  
export default globalCart;