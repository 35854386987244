import Model from "./ShopModel";
import View from "./ShopView";
import CartController from "../../../CartController";

class Controller extends CartController {
  constructor(model, view) {
    super();
    this._model = model;
    this._view = view;

    this._model.on("gotProducts", (results) => {
      let in_cart = false;

      results.forEach((product, index) => {
        in_cart = this.cart.idInCart(product.id) ? true : false;
        results[index]["in_cart"] = in_cart;
      });
      this._view.render(results);
    });

    this._model.on("gotProductGroups", (results) => {
      this._view.renderFilters(results, "product_groups");
    });

    this._model.on("gotRecommendedProducts", (results) => {
      let in_cart = false;

      results.forEach((product, index) => {
        in_cart = this.cart.idInCart(product.id) ? true : false;
        results[index]["in_cart"] = in_cart;
      });
      this._view.setRecommendedProducts(results);
    });

    this._view.on("purchase", (purchase) => {
      if (this.cart.idInCart(purchase.id)) {
        this.cart.remove(purchase.id);
      } else {
        this._model
          .checkProductAvailability(purchase.id)
          .then((result) => {
            if (result.data.available === true) {
              this.cart.add(purchase);
            } else {
              this._view.handleErrorMsg(
                "A kiválasztott termék jelenleg nem elérhető!"
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });

    this._view.on("cartCheck", (product) => {
      let in_cart = this.cart.idInCart(product.id) ? true : false;
      this._view.renderCartBtn(in_cart, product);
    });

    this.cart.on("update", (newCart) => {
      this._view.reRenderCartBtn();
    });
  }
}

const Shop = (function () {
  return {
    init: () => {
      const model = new Model();
      const view = new View(model);
      const controller = new Controller(model, view);
    },
  };
})();

export default Shop;
