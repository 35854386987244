import Component from '../../Component';
import { formatPrice } from '../../helpers/helpers';

class CartView extends Component {
  constructor(model) {
    super();
    this._model = model;
    this.state = {};

    $('.cart-sub-list').click((e) => {
      if ($(e.target).is(".glyphicon-minus")) {
        this._model.removeFromCart($(e.target).attr('data-id'));
      }
    })
  }

  updateCart(cart) {
    this.emit("update", cart);
    console.log('updating cart');
    $('#cart_count').html(cart.length);
    let list = cart.map(i => {
      let subtext = "";
      if (i.type === 'photo' && (i.size === 'canvas' || i.size === 'paper')) {
        if (i.size === 'canvas') {
          subtext = 'Vászon:<br>' + CONFIG['materials'][i.material] + ' | ' + i.dimensions;
        }
        if (i.size === 'paper') {
          subtext = 'Papír:<br>' + CONFIG['materials'][i.material] + ' | ' + i.dimensions;
        }
      } else if (i.type === 'shop_product') {
        let text = ``;
        i?.attributes?.map((attribute) => {
          text += `<br>${attribute.display}: ${attribute.value}`;
        })
        subtext = `Termék: ${decodeURIComponent(text)}`;
        subtext += `<br>${i.quantity} db`;

        if (i.is_preorder === "true") {
          subtext += `<br><span class="text-danger">Előrendelés</span>`;
        }
      } else if (i.type === 'gift_code') {
        subtext = "Ajándékkártya";
      } else if (i.type === 'personal_collection') {
        subtext = CONFIG['prices'][i.type]['shop_product']['title'];
      } else if (i.type === 'parcel_point') {
        subtext = CONFIG['prices'][i.type]['shop_product']['cart_title'];
      } else if (i.type === 'post_point') {
        subtext = CONFIG['prices'][i.type]['shop_product']['cart_title'];
      } else if (i.type === 'ldap_discount') {
        subtext = CONFIG[i.type]['general']['title'];
      } else if (i.type === 'vienna_collection_point') {
        subtext = CONFIG['prices'][i.type]['shop_product']['cart_title'];
      } else {
        subtext = CONFIG['prices'][i.type][i.size]['title'];
      }

      let title = '';
      if (i.type === 'photo') {
        title = `
          <b><a href=${SITE_URL + 'photobank/item/' + encodeURIComponent(i.url)}>${i.id}</a></b><br>
        `
      } else if (i.type === 'shop_product') {
        title = `
          <b><a href=${SITE_URL}webshop/product/${i.id}>${decodeURIComponent(i.name)}</a></b><br>
        `
      }
      return `
        <div class="cart-sub-item">
          ${this.cartIcon(i)}
          <div class="c">
            <div class="c-title">
              ${title}
              <b>${subtext}</b><br>
              ${(i['type'] !== 'promo_code') ? `
              ${i['discounted'] ? `
              <b><span style="text-decoration:line-through">${formatPrice(i['price'] + i['discounted'])} HUF</span> <i class="fas fa-long-arrow-alt-right"></i> 
              <span class="text-success">${formatPrice(i['price'])} HUF</span>
              ` : `
              <b>${formatPrice(i['price'])} HUF</b>
              `}
              ` : ''}
            </div>
            ${
              i.type !== 'shipment' && i.type !== 'personal_collection' && i.type !== 'parcel_point' && i.type !== 'post_point' && i.type !== 'ldap_discount' && i.type !== 'vienna_collection_point' ? `
              <div class="c-remove"><span class="glyphicon glyphicon-minus" data-id="${i.id}"></span></div>
              ` : ""
            }
          </div>
        </div>
        `
    })
    $(".cart-sub-list").html(list);
    if (!cart || !cart.length) {
      $('.cart-sub').addClass('empty');
      $('.cart-sub .btn').addClass('disabled');
      $('#cart-text').html('A kosár üres.')
    } else {
      $('.cart-sub').removeClass('empty');
      $('.cart-sub .btn').removeClass('disabled');
      $('#cart-text').html(`Összesen <b>${cart.length}</b> elem`)
    }
  }

  cartIcon(item) {
    if (item.type === 'photo') {
      return `<div class="i" style="background-image: url(${SITE_URL + 'images/small/' + encodeURIComponent(item.url)})"></div>`;
    } else if (item.type === 'package' || item.type === 'payment') {
      return `<div class="i"><i class="fas fa-money-bill-alt"></i></div>`;
    } else if (item.type === 'shipment') {
      return `<div class="i"><i class="fas fa-truck"></i></div>`;
    } else if (item['type'] === 'promo_code') {
      return `<div class="i"><i class="fas fa-percentage"></i></div>`;
    } else if (item['type'] === 'gift_code') {
      return `<div class="i"><i class="fas fa-percentage"></i></div>`;
    } else if (item.type === 'shop_product') {
      return `<div class="i" style="background-image: url(${SITE_URL}public/uploads/${decodeURIComponent(item.img)})"></div>`
    } else if (item.type === 'personal_collection') {
      return `<div class="i"><i class="fas fa-box"></i></div>`;
    } else if (item.type === 'parcel_point') {
      return `<div class="i"><i class="fas fa-map-marker-alt"></i></div>`;
    } else if (item.type === 'post_point') {
      return `<div class="i"><i class="fas fa-map-marker-alt"></i></div>`;
    } else if (item['type'] === 'ldap_discount') {
      return `<div class="i"><i class="fas fa-percentage"></i></div>`;
    } else if (item['type'] === 'vienna_collection_point') {
      return `<div class="i"><i class="fas fa-map-marker-alt"></i></div>`;
    }
  }
}

export default CartView;