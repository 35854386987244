import App from "../app";
import CategorySettings from "./admin/category/CategorySettings";
import ProductSettings from "./admin/product/ProductSettings";
import ProductGroupsSettings from "./admin/product_group/ProductGroupSettings";
import AttributeSettings from "./admin/attribute/AttributeSettings";
import NewProduct from "./admin/product/new/NewProduct";
import ShopProduct from "./front/shopProduct/ShopProduct";
import Shop from "./front/shop/Shop";

const Modules = {
  ProductSettings,
  ProductGroupsSettings,
  CategorySettings,
  AttributeSettings,
  NewProduct,
  ShopProduct,
  Shop,
};

App.init(Modules);
