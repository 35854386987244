import "core-js/stable";
import "regenerator-runtime/runtime";
import 'jquery';
import 'bootstrap';
require('bootstrap/dist/css/bootstrap.min.css')
require('bootstrap-social/bootstrap-social.css')
require('@fortawesome/fontawesome-free/css/all.min.css');
require('../css/main.css');
require('../css/news-dropdown-menu.css')
require('../css/email-modal.css');
import { getCookie } from './helpers/helpers'
import Accessibility from './submodules/accessibility/Accessibility';
import globalCart from "./submodules/globalCart/CartModule";
import mainMenu from "./main_menu/main_menu";
import secondaryMenu from "./secondary_menu/secondary_menu";

const mainModules = {
  Accessibility,
  globalCart,
  mainMenu,
  secondaryMenu,
}

$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();
  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
};

String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};

String.prototype.splice = function (idx, rem, str) {
  return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};

$.ajaxSetup({
  headers: { 'Anticsrf': getCookie('Anticsrf') }
});

const App = (function () {
  let state = {
    initiatedModules: []
  }

  function _initKeepAlive() {
    setInterval(() => {
      $.ajax({
        url: SITE_URL + 'keep_alive',
        method: 'GET',
        success: response => {
          
        },
        error: error => {
          console.log(error)
        }
      })
    }, (CONFIG['env'] === 'development' ? 30000 : 120000))
  }

  function _initModules(Modules) {
    let _modules = Array.from($('[data-module]')).map(item => $(item).attr('data-module'));
    _modules = [...new Set(_modules)];
    if (CONFIG.env !== 'production') console.log(_modules)
    if (_modules.length) {
      _modules.forEach(_module => {
        if(_module in Modules){
          state.initiatedModules.push(_module)
          Modules[_module].init(state)
        }
      })
    } else {
      console.log('no modules')
      let controller = new PageController();
    }
  }

  return {
    init: (Modules) => {
      _initModules(mainModules)
      _initModules(Modules);
      _initKeepAlive();
    }
  }
})()

export default App;