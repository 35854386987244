class Component {
  constructor() {
    this._events = {};
  }
  on(evt, listener) {
    (this._events[evt] || (this._events[evt] = [])).push(listener);
    return this;
  }
  emit(evt, arg) {
    (this._events[evt] || []).slice().forEach(lsn => lsn(arg));
  }

  setState(input = {}, callback = false) {
    let prevState = JSON.parse(JSON.stringify(this.state));
    let keys = Object.keys(input);
    keys.forEach(key => {
      this.state[key] = input[key];
    })
    this.state._last_updated = keys
    this.emit('stateUpdated', prevState);
    if (callback) {
      callback(this.state, prevState);
    }
  }

  getState(key = null) {
    if (key && this.state[key]) return JSON.parse(JSON.stringify(this.state[key]));
    if (key && !this.state[key]) return false;
    return JSON.parse(JSON.stringify(this.state));
  }
}

export default Component;