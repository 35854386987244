import ModelComponent from "../../../ModelComponent";

class CategorySettingsModel extends ModelComponent {
  constructor() {
    super();
  }

  getCategories() {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_categories",
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotCategories", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  saveCategory(data) {
    $.ajax({
      method: "POST",
      url: SITE_URL + "api/webshop/v1/new_category",
      data: data,
      success: (response) => {
        response = JSON.parse(response);
        if (response.status === 422) {
          this.emit("setErrors", response.errors);
        }

        this.emit("categorySaved", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  deleteCategory(id) {
    $.ajax({
      method: "POST",
      url: SITE_URL + "api/webshop/v1/delete_category",
      data: {
        id: id,
      },
      success: (response) => {
        response = JSON.parse(response);

        this.emit("categoryDeleted", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}

export default CategorySettingsModel;
