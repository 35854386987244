import Model from "./NewProductModel";
import View from "./NewProductView";
import PageController from "../../../../PageController";

class Controller extends PageController {
  constructor(model, view) {
    super();
    this._model = model;
    this._view = view;

    this._model.on("groupSaved", (results) => {
      this._view.groupSaved(results);
    });

    this._model.on("groupUploadError", (results) => {
      this._view.groupUploadError(results);
    });

    this._model.on("productUploadError", (results) => {
      this._view.productUploadError(results);
    });

    this._model.on("productSaved", (results) => {
      this._view.productSaved(results);
    });

    this._model.on("gotProductGroup", (results) => {
      this._view.setProductGroup(results);
    });

    this._model.on("gotGroupAttributes", (results) => {
      this._view.setAttributes(results);
    });

    this._model.on("gotGroupProducts", (results) => {
      this._view.setGroupProducts(results);
    });

    this._model.on("timestampRefreshed", (results) => {
      this._view.setTimestampBtn(results);
    });
  }
}

const NewProduct = (function () {
  return {
    init: () => {
      const model = new Model();
      const view = new View(model);
      const controller = new Controller(model, view);
    },
  };
})();

export default NewProduct;
