import Model from "./ProductGroupSettingsModel";
import View from "./ProductGroupSettingsView";
import PageController from "../../../PageController";

class Controller extends PageController {
  constructor(model, view) {
    super();
    this._model = model;
    this._view = view;

    this._model.on("gotProductGroups", (results) => {
      this._view.render(results);
    });

    this._model.on("setErrors", (errors) => {
      this._view.setErrors(errors);
    });

    this._model.on("groupSaved", (response) => {
      this._view.successSave();
    });

    this._model.on("groupDeleted", (response) => {
      this._view.successDelete();
    });

    this._model.on("gotGroupAttributes", (response) => {
      this._view.renderGroupAttributes(response);
    });

    this._model.on("gotAttributes", (response) => {
      this._view.setAttributes(response);
    });
  }
}

const ProductGroupsSettings = (function () {
  return {
    init: () => {
      const model = new Model();
      const view = new View(model);
      const controller = new Controller(model, view);
    },
  };
})();

export default ProductGroupsSettings;
