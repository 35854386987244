import ModelComponent from "../../../../ModelComponent";

class NewProductModel extends ModelComponent {
  constructor() {
    super();
  }

  async saveProductGroup(data) {
    await $.ajax({
      method: "POST",
      url: SITE_URL + "api/webshop/v1/save_product_group",
      processData: false,
      contentType: false,
      data: data,
      success: (response) => {
        response = JSON.parse(response);

        if (response.status === 200) {
          this.emit("groupSaved", response);
        } else if (response.status === 413) {
          this.emit("groupUploadError", response);
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  async saveProduct(data) {
    await $.ajax({
      method: "POST",
      url: SITE_URL + "api/webshop/v1/save_product",
      processData: false,
      contentType: false,
      data: data,
      success: (response) => {
        response = JSON.parse(response);

        if (response.status === 200) {
          this.emit("productSaved", response);
        } else if (response.status === 413) {
          this.emit("productUploadError", response);
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getProductGroup(id) {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_product_group?id=" + id,
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotProductGroup", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  async getGroupAttributes(id) {
    await $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_group_attributes?id=" + id,
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotGroupAttributes", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getGroupProducts(id) {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_group_products?id=" + id,
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotGroupProducts", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  deleteProduct(id) {
    $.ajax({
      method: "POST",
      url: SITE_URL + "api/webshop/v1/delete_product",
      data: {
        id: id,
      },
      success: (response) => {
        response = JSON.parse(response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  async refreshTimestamp(groupId) {
    await $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/refresh_timestamp?id=" + groupId,
      success: (response) => {
        response = JSON.parse(response);
        this.emit("timestampRefreshed", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}

export default NewProductModel;
