import ModelComponent from "../../../ModelComponent";

class ShopModel extends ModelComponent {
  constructor() {
    super();
  }

  getGroupedProducts() {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_grouped_products",
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotProducts", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getProductGroups() {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_product_groups",
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotProductGroups", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getRecommendedProducts() {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_recommended_products",
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotRecommendedProducts", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  search(isGrouped, value, category, filters, sortType) {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_grouped_products",
      data: {
        q: value,
        category: category,
        filters: filters,
        sort_type: sortType,
        is_grouped: isGrouped,
      },
      success: (response) => {
        response = JSON.parse(response);

        this.emit("gotProducts", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  checkProductAvailability(productId) {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: "GET",
        url: SITE_URL + "api/webshop/v1/check_product_availability",
        data: {
          id: productId,
        },
        success: (response) => {
          resolve(JSON.parse(response));
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }
}

export default ShopModel;
