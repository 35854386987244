//import Cart from './globalCart/Cart';
import { parseQuery } from './helpers/helpers';

class PageController {
  constructor() {
    //this.cart = new Cart();
    this.query = parseQuery(window.location.href);
  }
}

export default PageController;