class Pagination {
  constructor(maxVisiblePages = 5) {
    this.maxVisiblePages = maxVisiblePages;
    this.page = 1;
    this.totalPages = 1
    this.eventHandler = null;
  }

  setPage(number) {
    this.page = number;
  }

  getPage() {
    return this.page;
  }

  setTotalPages(number) {
    this.totalPages = number;
  }

  getTotalPages() {
    return this.totalPages;
  }

  setEventHandler(handler) {
    this.eventHandler = handler;
    $(document).on('click', '.page-link', (e) => {
      e.preventDefault();
      const text = $(e.target).text().trim();

      if (text === '«') {
        if (this.page > 1) {
          this.page--;
        }
      } else if (text === '»') {
        if (this.page < this.totalPages) {
          this.page++;
        }
      } else {
        this.page = parseInt(text);
      }

      if (typeof this.eventHandler === 'function') {
        this.eventHandler(this.page);
      }
    });
  }

  renderPagination(currentPage, totalPages, containerSelector) {
    const pagination = $(containerSelector);
    let paginationHTML = `
        <nav aria-label="...">
            <ul class="pagination">`;

    let startPage = Math.max(1, currentPage - Math.floor(this.maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + this.maxVisiblePages - 1);

    paginationHTML += `<li class="page-item ${currentPage === 1 ? 'disabled' : ''}" id="prev-page">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                       </li>`;

    if (startPage > 1) {
      paginationHTML += `<li class="page-item">
                           <a class="page-link" href="#">1</a>
                         </li>`;
      paginationHTML += `<li class="page-item disabled">
                           <span>...</span>
                         </li>`;
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationHTML += `<li class="page-item ${i === currentPage ? 'active' : ''}">
                           <a class="page-link" href="#">${i}</a>
                         </li>`;
    }

    if (endPage < totalPages) {
      paginationHTML += `<li class="page-item disabled">
                           <span>...</span>
                         </li>`;
      paginationHTML += `<li class="page-item">
                           <a class="page-link" href="#">${totalPages}</a>
                         </li>`;
    }



    paginationHTML += `<li class="page-item ${currentPage === totalPages ? 'disabled' : ''}" id="next-page">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                       </li>`;

    paginationHTML += `</ul></nav>`;

    pagination.html(paginationHTML);
  }
}

export default Pagination;