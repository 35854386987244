import ViewComponent from "../../../ViewComponent";
import "select2";
require("select2/dist/css/select2.css");

class ProductGroupSettingsView extends ViewComponent {
  constructor(model) {
    super();
    this._model = model;
    this.state = {
      data: null,
      attributes: null,
    };

    this._model.getProductGroups();
    this._model.getAttributes();
    this.addListeners();
  }

  render(results) {
    this.setState({ data: results });
    $("#shop_product_group_list").html("");

    $("#shop_product_group_list").append(`
    <div class="product_group_list_header">
      <div class="header_item">
          #Azonosító
      </div>
      <div class="header_item">
          Név
      </div>
      <div class="header_item">
          Kategória
      </div>
      <div class="header_item">
      </div>
    </div>
    `);

    results.forEach((productGroup) => {
      $("#shop_product_group_list").append(`
      <div class="product_group_list_row" data-id=${productGroup.id}>
        <div class="table_list_item">
          #${productGroup.id}
        </div>
        <div class="table_list_item">
          ${productGroup.group_name}
        </div>
        <div class="table_list_item">
          ${productGroup.category_name}
        </div>
        <div class="table_list_item">
            <div class="list_action_btns">
                <a href="${SITE_URL}webshop/edit-group/${productGroup.id}"><span class="shop_edit_btn list_action_btn text-warning"><i class="fas fa-edit"></i></span></a>
                <span class="shop_delete_btn list_action_btn text-danger"><i class="fas fa-trash-alt"></i></span>
            </div>
        </div>
      </div>
      `);
    });

    $(".shop_delete_btn").on("click", (e) => {
      let id = $(e.target).closest("[data-id]").data("id");

      this.deleteGroup(id);
    });
  }

  renderGroupAttributes(attributes = null) {
    let options = "";
    let requiredAttributes = [];

    if (this.state.attributes) {
      this.state.attributes.forEach((item) => {
        options += `<option value="${item.id}" title="${item.name}">${item.display}</option>`;
      });

      let HTML = `
      <div class="col-sm-12">
        <label for="attributes_select" class="form-label">Attribútumok</label><br>
        <select class="form-control" id="attributes_select" name="attributes_select[]" multiple="multiple">
          ${options}
        </select>
        <div class="invalid-feedback text-danger" id="error_attributes_select">

        </div>
      </div>
      `;

      $("#attributes-container").append(HTML);

      $(document).ready(function () {
        $("#attributes_select").select2({
          width: "100%",
          language: {
            noResults: function (params) {
              return "Nincs találat.";
            },
          },
        });
      });

      if (attributes) {
        attributes.forEach((attribute) => {
          requiredAttributes.push(parseInt(attribute.attribute_id));
        });
      }

      $("#attributes_select").val(requiredAttributes);
    }
  }

  addListeners() {
    $("#add_new_btn").on("click", () => {
      this.openNewModal();
    });

    $("#save-product").on("click", () => {
      this.saveForm();
    });
  }

  setAttributes(attributes) {
    if (attributes) {
      this.setState({ ...this.state, attributes: attributes });
    }
  }

  openNewModal() {
    this.clearErrors();
    $("#attributes-container").html("");
    $("#group_name").val("");
    $("#group_id").val("");
    $("#description").val("");
    $("#category").val("");
    this.renderGroupAttributes();
    $("#group_modal").modal("show");
  }

  saveForm() {
    this.clearErrors();
    let requiredAttributes = [];

    let data = {
      group_name: $("#group_name").val(),
      description: $("#description").val(),
      category: $("#category").val() ? parseInt($("#category").val()) : "",
      attributes_select: $("#attributes_select").select2("data"),
    };

    if (this.validateForm(data)) {
      data?.attributes_select.forEach((attribute) => {
        requiredAttributes.push(parseInt(attribute.id));
      });
      data.attributes_select = requiredAttributes;

      this._model.saveProductGroup(data);
    }
  }

  successSave() {
    $("#group_modal").modal("hide");
    this._model.getProductGroups();
  }

  validateForm(data) {
    let isValid = true;

    if (data) {
      for (let [key, value] of Object.entries(data)) {
        if (!value) {
          isValid = false;
          this.renderValidationError(key, "Mező kitöltése kötelező");
        }
      }
    }

    return isValid;
  }

  editModal(id) {
    let group = this.state.data.find((group) => group.id == id);
    $("#attributes-container").html("");
    $("#group_name").val(group.group_name);
    $("#group_id").val(group.id);
    $("#description").val(group.description);
    $("#category").val(group.id_category);
    $("#group_modal").modal("show");
    this.clearErrors();

    this._model.getGroupAttributes(id);
  }

  deleteGroup(id) {
    if (confirm("Biztosan törölni szeretné?") == true) {
      this._model.deleteGroup(id);
    }
  }

  successDelete() {
    this._model.getProductGroups();
  }

  renderValidationError(idAttribute, error) {
    $(`#error_${idAttribute}`).html(error);
  }

  clearErrors() {
    $('div[id^="error_"]').html("");
  }

  setErrors(data) {
    for (let [key, value] of Object.entries(data)) {
      $(`#${key}`).html(value);
    }
  }
}

export default ProductGroupSettingsView;
