
const MainMenu = (function () {

  const setupListeners = () => {
    $(".extended-main-menu .main-toggle-title").click((e) => {
      $('#main-extended_nav').collapse('toggle');
    })
  }

  return {
    init: () => {
      setupListeners();
    }
  }

})()

export default MainMenu;