import ViewComponent from "../../../../ViewComponent";
import "select2";
require("select2/dist/css/select2.css");

class NewProductView extends ViewComponent {
  constructor(model) {
    super();
    this._model = model;
    this.state = {
      products: [],
      product_group: [],
      attributeTypes: null,
      errors: null,
      requiredAttributes: null,
      form_type: null,
      productsData: null,
      editableProducts: [],
      groupId: null,
      savableProductsCount: 0,
      saveCounter: 0,
      timestampModified: false,
    };

    this.addListeners();
    this.getAttributeTypes().then(() => {
      this.setFormType();
    });
  }

  async setFormType() {
    let form_type = $("#product_content").data("form_type");

    this.setState({ ...this.state, form_type: form_type });

    if (form_type === "edit") {
      let group_id = $("#group_id").val();
      this.renderTimestampBtn();
      await this._model.getGroupAttributes(group_id).then(() => {
        this._model.getGroupProducts(group_id);
      });
      await this._model.getProductGroup(group_id);
    }
  }

  renderTimestampBtn() {
    $("#timestamp-btn").html('<button type="button" class="btn btn-primary" id="update-timestamp" data-loading-text="Frissítés...">Előresorolás</button>');

    $("#update-timestamp").on("click", (e) => {
      console.log('timestamp modified');
      $("#update-timestamp").button('loading');
      const groupId = $("#group_id").val();
      this.state.timestampModified = true;
      this._model.refreshTimestamp(groupId);
    });
  }

  async setAttributes(attributes) {
    let requiredAttributes = [];
    if (attributes) {
      attributes.forEach((attribute) => {
        requiredAttributes.push(parseInt(attribute.attribute_id));
      });
    }

    $("#attributes_select").val(requiredAttributes).change();
  }

  setGroupProducts(products) {
    this.setState({ ...this.state, products: products });

    if (products) {
      products.forEach((product) => {
        let data = {
          id: product.id,
          product_name: product.product_name,
          product_price: product.price,
          in_stock: product.in_stock,
          product_description: product.description ?? "",
          product_img: product.img,
          is_active: product.is_active,
          is_consignment: product.is_consignment,
          is_preorder: product.is_preorder,
          is_recommended: product.is_recommended,
          is_print: product.is_print,
          is_hidden: product.is_hidden,
          inventory_code: product.inventory_code,
          block_type: "existing",
        };

        if (this.state.requiredAttributes) {
          this.state.requiredAttributes.forEach((attribute) => {
            data["product_" + attribute.name] = product?.attributes.find(
              (element) => element.id_attribute === attribute.id
            ).value;
          });
        }
        let block = this.createProductBlock(data);

        $("#products-container").append(block);

        let form = $(`.product_form[data-productid="${product.id}"]`);

        let deleteBtn = form.find("#delete-product");

        form.find(".edit_btn").html(`
        <div class="col-sm-2">
          <button type="button" class="btn btn-warning" id="edit-product">Szerkesztés</button>
        </div>
        `);

        form.find(".disabled_backdrop").addClass("active");

        let editBtn = form.find("#edit-product");
        let fileInput = form.find("#product_img");
        let previewImg = form.find(".preview_img");

        editBtn.on("click", (e) => {
          this.state.editableProducts.push(parseInt(product.id));
          form.find(".disabled_backdrop").removeClass("active");
        });

        deleteBtn.on("click", (e) => {
          if (confirm("Biztosan törölni szeretné?") == true) {
            let productId = $(e.target)
              .closest(".product_form")
              .data("productid");
            this._model.deleteProduct(productId);
            let block = $(e.target).closest(".product_form").remove();
          }
        });

        fileInput.on("change", (e) => {
          const file = e.target.files[0];

          if (file) {
            previewImg.attr("src", URL.createObjectURL(file));
          }
        });
      });
    }
  }

  render(results) {}

  renderAttributeSelect() {
    let options = "";

    this.state.attributeTypes.forEach((item) => {
      options += `<option value="${item.id}" title="${item.name}">${item.display}</option>`;
    });

    let HTML = `
    <div class="col-sm-12">
      <label for="attributes_select" class="form-label">Attribútumok</label><br>
      <select class="form-control" id="attributes_select" name="attributes_select[]" multiple="multiple">
        ${options}
      </select>
    </div>
    `;

    $("#attributes-container").append(HTML);

    $(document).ready(function () {
      $("#attributes_select").select2({
        width: "100%",
        language: {
          noResults: function (params) {
            return "Nincs találat.";
          },
        },
      });
    });

    $("#attributes_select").on("change", (e) => {
      let values = $("#attributes_select").select2("data");

      if (values.length === 0) {
        this.state.requiredAttributes = null;
        this.refreshAttributeInputs();
      } else {
        let attributes = [];

        values.forEach((attribute) => {
          attributes.push({
            id: attribute.id,
            title: attribute.text,
            name: attribute.title,
          });
        });

        this.state.requiredAttributes = attributes;

        this.refreshAttributeInputs();

        this.renderPrimaryAttrSelect();
      }
    });

    $("#attributes_select").on("select2:unselect", (e) => {
      this.renderPrimaryAttrSelect();
    });
  }

  renderPrimaryAttrSelect() {
    let options = '<option value="" title="">Válassz...</option>';

    $("#primary_attr").attr("disabled", true);

    if (this.state?.requiredAttributes?.length > 0) {
      this.state?.requiredAttributes?.forEach((item) => {
        options += `<option value="${item.id}" title="${item.name}">${item.title}</option>`;
      });

      $("#primary_attr").attr("disabled", false);
    }

    $("#primary_attr").html(options);
  }

  refreshAttributeInputs() {
    let oldAttributeInputs = $(".product_form").first().find(".attribute_item");
    let oldAttributeIds = [];
    let requiredAttributeIds = [];

    for (let oldInput of oldAttributeInputs) {
      oldAttributeIds.push($(oldInput).data("attributeid"));
    }

    if (this.state.requiredAttributes) {
      this.state.requiredAttributes.forEach((item) => {
        requiredAttributeIds.push(parseInt(item.id));
      });
    }

    let deletableInput = oldAttributeIds.filter(
      (id) => !requiredAttributeIds.includes(id)
    );

    deletableInput.forEach((id) => {
      $(`.attribute_item[data-attributeid="${id}"]`).remove();
    });

    let newInput = requiredAttributeIds.filter(
      (id) => !oldAttributeIds.includes(id)
    );

    newInput.forEach((id) => {
      let input = this.state.requiredAttributes.filter(
        (item) => parseInt(item.id) === id
      );

      let HTML = `
      <div class="col-sm-4 attribute_item" data-attributeid=${input[0].id}}>
        <label for="product_${input[0].name}" class="form-label">${input[0].title}</label>
        <input type="text" class="form-control" id="product_${input[0].name}" aria-describedby="product_${input[0].name}" name="product_${input[0].name}">
        <div class="invalid-feedback text-danger" id="error_product_${input[0].name}">

        </div>
      </div>
      `;

      $(".product_form #attribute_inputs").append(HTML);
    });
  }

  async getAttributeTypes() {
    await $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_attributes",
      success: (response) => {
        response = JSON.parse(response);
        this.setState({ ...this.state, attributeTypes: response });
        this.renderAttributeSelect();
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  setProductGroup(group) {
    this.state.product_group = group;
    $("#group_name").val(group.group_name);
    $("#group_description").val(group.description);
    $("#group_category").val(group.id_category);

    if (group?.group_img) {
      $("#group_image_container").html(`
        <img src="/public/uploads/${group.group_img}" class="img-thumbnail" alt="">
      `);
    }

    if (group?.primary_attr) {
      $("#primary_attr").val(group.primary_attr);
    }
  }

  addListeners() {
    $("#add-product").on("click", () => {
      this.addEmptyProductBlock();
    });

    $("#save-product").on("click", (e) => {
      e.preventDefault();
      this.clearErrors();
      this.handleFormSubmit();
    });

    $("#group_img").on("change", (e) => {
      const file = e.target.files[0];

      if (file) {
        $("#group_image_container").html(`
        <img src="${URL.createObjectURL(file)}" class="img-thumbnail" alt="">
      `);
      }
    });
  }

  createProductBlock(data = null) {
    let lastId = this.getLastBlockId();
    let emptyBlock = {
      id: lastId ? lastId + 1 : 1,
      product_name: "",
      product_price: "",
      in_stock: "",
      product_description: "",
      product_img: "webshop/test_image.jpg",
      is_active: false,
      is_consignment: false,
      is_preorder: false,
      is_recommended: false,
      is_print: false,
      is_hidden: false,
      inventory_code: "",
      block_type: "new",
    };

    let block = data ?? emptyBlock;

    let attributesHTML = "";

    if (this.state.requiredAttributes) {
      this.state.requiredAttributes.forEach((input) => {
        data === null ? (block["product_" + input.name] = "") : "";

        attributesHTML += `
        <div class="col-sm-4 attribute_item" data-attributeid=${input.id}}>
          <label for="product_${input.name}" class="form-label">${
          input.title
        }</label>
          <input type="text" class="form-control" value="${
            block["product_" + input.name]
          }" id="product_${input.name}" aria-describedby="product_${
          input.name
        }" name="product_${input.name}">
          <div class="invalid-feedback text-danger" id="error_product_${
            input.name
          }">

          </div>
        </div>
        `;
      });
    }
    let HTML = `
    <form action="" enctype="multipart/form-data" class="product_form" data-productid="${
      block.id
    }" data-blocktype="${block.block_type}">
        <div class="product_item mt-1">
          <div class="row">
            <div class="col-sm-4">
              <label for="product_name" class="form-label">Termék neve</label>
              <input type="text" class="form-control" value="${
                block.product_name
              }" id="product_name" aria-describedby="product_name" name="product_name">
              <div class="invalid-feedback text-danger" id="error_product_name">

              </div>
            </div>
            <div class="col-sm-2">
              <label for="product_price" class="form-label">Termék ára</label>
              <input type="number" class="form-control" value="${
                block.product_price
              }" id="product_price" aria-describedby="product_price" name="product_price">
              <div class="invalid-feedback text-danger" id="error_product_price">

              </div>
            </div>
            <div class="col-sm-2">
              <label for="in_stock" class="form-label">Belső raktár</label>
              <input type="number" class="form-control" value="${
                block.in_stock
              }" id="in_stock" aria-describedby="in_stock" name="in_stock">
              <div class="invalid-feedback text-danger" id="error_in_stock">

              </div>
            </div>
            <div class="col-sm-3">
              <label for="inventory_code" class="form-label">Leltár kód</label>
              <input type="text" class="form-control" value="${
                block.inventory_code || ""
              }" id="inventory_code" aria-describedby="inventory_code" name="inventory_code">
              <div class="invalid-feedback text-danger" id="error_inventory_code">

              </div>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-sm-4">
              <label for="product_description" class="form-label">Termék leírása</label>
              <textarea rows="4" class="form-control" id="product_description" aria-describedby="product_description" name="product_description">${
                block.product_description
              }</textarea>
              <div class="invalid-feedback text-danger" id="error_product_description">

              </div>
            </div>
            <div class="col-sm-4">
              <label for="product_img" class="form-label">Kép</label>
              <input type="file" class="form-control" id="product_img" aria-describedby="product_img" name="product_img">
              <div class="invalid-feedback text-danger" id="error_product_img">

              </div>
            </div>
            <div class="col-sm-2">
              <div class="img_holder">
                <img class="preview_img" src="/public/uploads/${
                  block.product_img
                }" width="100" height="100">
              </div>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-sm-3 toggle_switch">
              <span class="form-label switch_label">Aktív</span>
              <input id="is_active_${
                block.id
              }" name="is_active" type="checkbox" ${
      block.is_active == true ? "checked" : ""
    } />
              <label for="is_active_${block.id}" class="label-success"></label>
            </div>
            <div class="col-sm-3 toggle_switch">
              <span class="form-label switch_label">Bizományosi</span>
              <input id="is_consignment_${
                block.id
              }" name="is_consignment" type="checkbox" ${
      block.is_consignment == true ? "checked" : ""
    } />
              <label for="is_consignment_${
                block.id
              }" class="label-success"></label>
            </div>
            <div class="col-sm-3 toggle_switch">
              <span class="form-label switch_label">Előrendelhető</span>
              <input id="is_preorder_${
                block.id
              }" name="is_preorder" type="checkbox" ${
      block.is_preorder == true ? "checked" : ""
    } />
              <label for="is_preorder_${
                block.id
              }" class="label-success"></label>
            </div>
            <div class="col-sm-3 toggle_switch">
              <span class="form-label switch_label">Ajánlott</span>
              <input id="is_recommended_${
                block.id
              }" name="is_recommended" type="checkbox" ${
      block.is_recommended == true ? "checked" : ""
    } />
              <label for="is_recommended_${
                block.id
              }" class="label-success"></label>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-sm-3 toggle_switch">
              <span class="form-label switch_label">Nyomdai termék</span>
              <input id="is_print_${
                block.id
              }" name="is_print" type="checkbox" ${
      block.is_print == true ? "checked" : ""
    } />
          <label for="is_print_${block.id}" class="label-success"></label>
        </div>
            <div class="col-sm-3 toggle_switch">
              <span class="form-label switch_label">Rejtett</span>
              <input id="is_hidden_${
                block.id
              }" name="is_hidden" type="checkbox" ${
                block.is_hidden == true ? "checked" : ""
              } />
          <label for="is_hidden_${block.id}" class="label-success"></label>
        </div>
          </div>
          <div class="row mt-1">
            <div id="attribute_inputs">
              ${attributesHTML}
            </div>
          </div>
          <div class="row mt-1 btn_wrapper">
            <div class="edit_btn"></div>
            <div class="col-sm-2">
              <button type="button" class="btn btn-danger" id="delete-product">Törlés</button>
            </div>
          </div>

          <div class="product_status">

          </div>
          <div class="disabled_backdrop"></div>
        </div>
      </form>
    `;

    return HTML;
  }

  addEmptyProductBlock() {
    let block = this.createProductBlock();

    $("#products-container").append(block);

    let lastId = this.getLastBlockId();
    let deleteBtn = $(`.product_form[data-productid="${lastId}"]`).find(
      "#delete-product"
    );

    let fileInput = $(`.product_form[data-productid="${lastId}"]`).find(
      "#product_img"
    );

    let previewImg = $(`.product_form[data-productid="${lastId}"]`).find(
      ".preview_img"
    );

    fileInput.on("change", (e) => {
      const file = e.target.files[0];

      if (file) {
        previewImg.attr("src", URL.createObjectURL(file));
      }
    });

    deleteBtn.on("click", (e) => {
      let block = $(e.target).closest(".product_form").remove();
    });
  }

  getLastBlockId() {
    let productDiv = $("#products-container").find(".product_form").last();

    return productDiv.data("productid");
  }

  handleFormSubmit() {
    let productsData = [];
    let productDivs = $("#block-container").find(".product_form");
    let productGroupForm = $("#product_group_form").serializeArray();
    let groupImg = null;

    for (let block of productDivs) {
      let dataArray = $(block).serializeArray();

      let dataObject = {
        block_id: $(block).data("productid"),
        block_type: $(block).data("blocktype"),
      };

      for (let blockInput of dataArray) {
        dataObject[blockInput.name] = blockInput.value;
      }

      dataObject["is_active"] = $(block)
        .find(`#is_active_${dataObject.block_id}`)
        .prop("checked");
      dataObject["is_consignment"] = $(block)
        .find(`#is_consignment_${dataObject.block_id}`)
        .prop("checked");
      dataObject["is_preorder"] = $(block)
        .find(`#is_preorder_${dataObject.block_id}`)
        .prop("checked");
      dataObject["is_recommended"] = $(block)
        .find(`#is_recommended_${dataObject.block_id}`)
        .prop("checked");
      dataObject["is_print"] = $(block)
        .find(`#is_print_${dataObject.block_id}`)
        .prop("checked");
      dataObject["is_hidden"] = $(block)
        .find(`#is_hidden_${dataObject.block_id}`)
        .prop("checked");

      let file = $(block).find("#product_img")[0].files[0];

      dataObject["product_img"] = file;

      productsData.push(dataObject);
    }

    groupImg = $("#group_img")[0].files[0];

    let groupIsValid = this.validateGroupFormData(productGroupForm);
    let productIsValid = this.validateProductFormData(productsData);

    if (groupIsValid && productIsValid) {
      this.saveProductGroup(productGroupForm, groupImg);
      this.state.productsData = productsData;
    }
  }

  saveProductGroup(formData, img = null) {
    $("#group_status").html(`
    <div class="status_backdrop">
      <div class="loader_container">
        <div class="shop_spinner">
          <div class="success_icon"></div>
        </div>
      </div>
    </div>
    `);

    let data = new FormData();
    for (let input of formData) {
      data.append(input.name, input.value);
    }

    if (this.state.requiredAttributes) {
      data.append(
        "required_attributes",
        JSON.stringify(this.state.requiredAttributes)
      );
    }

    if (img) {
      data.append("group_img", img);
    }

    if (this.state.form_type === "edit") {
      data.append("img_name", this.state.product_group?.group_img || null);
    }

    this._model.saveProductGroup(data).then(() => {
      if (this.state.errors !== true) {
        this.saveProducts();
      }
    });
  }

  groupSaved(result) {
    this.state.groupId = result.data.id_group;
    this.state.errors = false;

    $("#group_status").find(".shop_spinner").addClass("completed");
    $("#group_status").find(".success_icon").addClass("success");
  }

  saveProducts() {
    this.setSavableProductsCount();

    if (this.state.savableProductsCount === 0 && this.state.errors !== true) {
      this.redirect();
    }

    this.state?.productsData?.forEach((productForm) => {
      let formData = new FormData();
      formData.append("id_group", this.state.groupId);
      formData.append(
        "required_attributes",
        JSON.stringify(this.state.requiredAttributes)
      );

      if (
        productForm.block_type === "new" ||
        this.state.editableProducts.includes(productForm.block_id)
      ) {
        $(`.product_form[data-productid="${productForm.block_id}"]`).find(
          ".product_status"
        ).html(`
          <div class="status_backdrop">
            <div class="loader_container">
              <div class="shop_spinner">
                <div class="success_icon"></div>
              </div>
            </div>
          </div>
          `);
        for (let [name, value] of Object.entries(productForm)) {
          if (name !== "product_img") {
            formData.append(name, value);
          }
        }

        if (productForm.block_type === "existing") {
          let product = this.state.products.find(
            (product) => product.id == productForm.block_id
          );

          formData.append("img_name", product.img);
        }

        let file = $(
          `.product_form[data-productid="${productForm.block_id}"]`
        ).find("#product_img")[0].files[0];

        formData.append("product_img", file);

        this._model.saveProduct(formData);
      }
    });
  }

  setSavableProductsCount() {
    this.state.savableProductsCount = 0;
    this.state?.productsData?.forEach((productForm) => {
      if (
        productForm.block_type === "new" ||
        (this.state.editableProducts.includes(productForm.block_id) &&
          productForm.block_type === "existing")
      ) {
        this.state.savableProductsCount++;
      }
    });
  }

  productSaved(result) {
    this.state.saveCounter++;
    let block = $(`.product_form[data-productid=${result.data.block_id}]`);
    block.find(".shop_spinner").addClass("completed");
    block.find(".success_icon").addClass("success");

    if (this.state.saveCounter === this.state.savableProductsCount) {
      this.redirect();
    }
  }

  validateGroupFormData(data) {
    let isValid = true;
    let inputExceptions = ["group_id", "primary_attr"];
    if (data) {
      data.forEach((input) => {
        if (!inputExceptions.includes(input.name) && input.value === "") {
          isValid = false;
          this.renderValidationError(input.name, "Mező kitöltése kötelező");
        }
      });
    }

    return isValid;
  }

  validateProductFormData(data) {
    let isValid = true;

    if (data) {
      data.forEach((input) => {
        let fileInput = $(
          `.product_form[data-productid="${input.block_id}"]`
        ).find("#product_img");

        if (fileInput[0].files.length === 0 && input.block_type === "new") {
          isValid = false;
          this.renderProductValidationError(
            input.block_id,
            "product_img",
            "File kiválasztása kötelező"
          );
        }

        for (let [name, value] of Object.entries(input)) {
          if (name !== "product_description" && value === "") {
            isValid = false;
            this.renderProductValidationError(
              input.block_id,
              name,
              "Mező kitöltése kötelező"
            );
          }
        }
      });
    }

    return isValid;
  }

  renderValidationError(idAttribute, error) {
    $(`#error_${idAttribute}`).html(error);
  }

  renderProductValidationError(blockId, idAttribute, error) {
    $(`.product_form[data-productid="${blockId}"]`)
      .find(`#error_${idAttribute}`)
      .html(error);
  }

  clearErrors() {
    $('div[id^="error_"]').html("");
  }

  groupUploadError(result) {
    this.state.errors = true;
    $("#group_status").html("");
    $("#error_group_img").html(
      "Kép feltöltése sikertelen, a fájl mérete túl nagy"
    );
  }

  productUploadError(result) {
    this.state.errors = true;
    $(`.product_form[data-productid="${result?.data?.block_id}"]`)
      .find(".product_status")
      .html("");

    $(`.product_form[data-productid="${result?.data?.block_id}"]`)
      .find(`#error_product_img`)
      .html("Kép feltöltése sikertelen, a fájl mérete túl nagy");
  }

  redirect() {
    window.location = SITE_URL + "webshop/admin/products";
  }

  setTimestampBtn(result) {
    $("#update-timestamp").button('reset');

    if (result.status === 200) {
      $("#timestamp-btn").html("<b>Időbélyeg frissítve!</b>");
    } else {
      $("#timestamp-btn").html("<b>Frissítés során hiba történt!</b>");
    }
  }
}

export default NewProductView;
