import ViewComponent from "../../../ViewComponent";
import "slick-carousel";
import RotateSpriteImg from "./RotateSpriteImg";

require("slick-carousel/slick/slick.css");

class ShopProductView extends ViewComponent {
  constructor(model) {
    super();
    this._model = model;
    this.state = {
      product: null,
      requiredAttributes: null,
      groupProducts: null,
      isError: false,
      in_cart: false,
      in_stock: true,
      primaryAttr: null,
      def3dImgOptions: {
        ext: ".jpg",
        imgCount: 19,
        speed: 10,
      },
      clickPosition: null,
      currentImgIndex: 0,
      webshopUrl: "tvmacibolt",
    };

    this.rotate3d = new RotateSpriteImg();

    this._model.getProduct(this.getProductId());
    this._model.getRandomProducts();

    this.addListeners();
  }

  render(results, in_cart) {
    this.setState({ ...this.state, product: results });
    this.setState({ ...this.state, in_cart: in_cart ? true : false });

    let primaryAttr = results?.attributes?.find(
      (item) => item.id == results?.primary_attr
    );

    if (primaryAttr) {
      this.setState({
        ...this.state,
        primaryAttr: {
          id: results.primary_attr,
          name: primaryAttr.name,
          value: primaryAttr.value,
        },
      });
    }

    this.renderCartBtn();

    this._model.getProductAttributeOptions(results.id_group);
    this._model.getRequiredAttribtues(results.id_group);
    this._model.getGroupProducts(results.id_group);

    $("#product-title").html(results.product_name);
    $("#group-title").html(results.group_name);
    $("#product-description").html(results.product_description);
    $("#product-price").html(
      new Intl.NumberFormat("hu-HU", {
        style: "currency",
        currency: "HUF",
        minimumFractionDigits: 0,
      }).format(results.price)
    );

    $("#main_product_img").html(`
      <img src="/public/uploads/${results.img}" class="img-thumbnail" alt="">
    `);

    let container = $(".img-360-container");
    let imgHolder = $(".sprite_container .product_sprite");
    let mouseIcon = $(".mouse-icon-wrapper");
    let loader = $("#product_img_loader");
    this.rotate3d.set(container, imgHolder, mouseIcon, results, loader);
  }

  renderAttributeOptions(results) {
    $(`#product-parameters`).html();

    for (let [name, data] of Object.entries(results)) {
      let options = ``;
      data.items.forEach((attribute) => {
        options += `<option value="${attribute.value}">${attribute.value}</otpion>`;
      });

      let HTML = `
        <div>
          <label for="product_category" class="form-label">${data.display}</label>
          <select class="form-control shop_form_attributes" aria-label="product_${name}" id="product_${name}" name="product_${name}">
            ${options}
          </select>
        </div>
      `;

      $(`#product-parameters`).append(HTML);

      $(`#product_${name}`).on("change", (e) => {
        let query = [];

        if (this.state.requiredAttributes) {
          this.state.requiredAttributes.forEach((attribute) => {
            let value = $(`#product_${attribute.attribute_name}`).val();
            query.push({ id_attribute: attribute.attribute_id, value: value });
          });
        }

        if (name === this.state.primaryAttr?.name) {
          let selectedValue = $(`#product_${name}`).val();
          $(".img_holder").removeClass("active");
          $(`.img_holder[data-value="${selectedValue}"]`).addClass("active");
          this.state.primaryAttr.value = selectedValue;
        }

        this._model.getProductByAttributes(query, this.state.product.id_group);
      });
    }

    this.state?.product?.attributes?.forEach((attribute) => {
      $(`#product_${attribute.name}`).val(attribute.value);
    });
  }

  renderCartBtn(update = false, value = null) {
    if (update) {
      this.setState({ ...this.state, in_cart: value });
    }

    if (this.state.product?.is_preorder == true) {
      $("#product_info").html(`<h4 class="text-danger">Előrendelhető</h4>`);
    } else {
      $("#product_info").html("");
    }

    if (
      (this.state.product?.is_active == false ||
        parseInt(this.state.product?.in_stock) === 0) &&
      this.state.product?.is_preorder != true
    ) {
      this.state.isError = true;
      let message = { error: "Ez a termék jelenleg nem elérhető!" };
      this.renderError(message);
    }

    if (this.state.in_cart) {
      $("#cart-btn").html(`
        <span><i class="fas fa-check"></i></span>
        <span class="btn_title">Kosárban</span>
      `);

      $("#cart-btn").addClass("in_cart");
    } else {
      $("#cart-btn").html(`
        <span><i class="fas fa-cart-arrow-down"></i></span>
        <span class="btn_title">Kosárba</span>
      `);

      $("#cart-btn").removeClass("in_cart");
    }

    if (this.state.isError || !this.state.in_stock) {
      $("#cart-btn").addClass("disabled");
    } else {
      $("#cart-btn").removeClass("disabled");
    }
  }

  setRequiredAttributes(attributes) {
    this.setState({ ...this.state, requiredAttributes: attributes });
  }

  setSelectedProduct(product) {
    $("#product_quantity").val(1);
    this.setState({ ...this.state, isError: false });
    $("#cart-btn").removeClass("disabled");
    $("#parameter-error").html("");
    this.setState({ ...this.state, product: product });

    this.emit("cartCheck", product);
    this.checkInventory($("#product_quantity").val());

    $("#product-title").html(product.product_name);
    $("#product-description").html(product.product_description);
    $("#product-price").html(
      new Intl.NumberFormat("hu-HU", {
        style: "currency",
        currency: "HUF",
        minimumFractionDigits: 0,
      }).format(product.price)
    );

    $("#main_product_img").html(`
      <img src="/public/uploads/${product.img}" class="img-thumbnail" alt="">
    `);

    product?.attributes?.forEach((attribute) => {
      $(`#product_${attribute.name}`).val(attribute.value);
    });

    if (product.hasSpriteImg) {
      this.rotate3d.switchProductImg(product);
    } else {
      $(".img-360-container").removeClass("loaded");
      $("#product_img_loader").removeClass("loading");
    }

    history.replaceState({}, '', `/${this.state.webshopUrl}/product/${product.slug}`);
  }

  setGroupProducts(products) {
    this.setPrimaryAttrProduct(products);

    $(document).ready(function () {
      $(".related_products_slider").slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow:
          '<div class="slider_arrow prev"><i class="fas fa-chevron-left"></i></div>',
        nextArrow:
          '<div class="slider_arrow next"><i class="fas fa-chevron-right"></i></div>',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 798,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 489,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    });

    let relatedProducts = ``;

    if (products.length > 1) {
      $(".related_products_title").html(`<h3>Kapcsolódó termékek</h3>`);

      products?.forEach((product) => {
        let attributes = ``;

        for (let i = 0; i < product?.attributes?.length; i++) {
          if (i == 2) {
            break;
          }
          attributes += `
          <div class="attributes_item">${product.attributes[i].display}: ${product.attributes[i].value}</div>
        `;
        }

        relatedProducts += `
        <div class="product_card">
          <a class="product_card_link" href="${SITE_URL}${this.state.webshopUrl}/product/${product.slug}"></a>
          <div class="product_card_body">
            <img src="/public/uploads/${product.img}" alt="" class="img-thumbnail">
            <a href="${SITE_URL}${this.state.webshopUrl}/product/${product.slug}"><div class="view_panel">Megtekintés</div></a>
          </div>
          <div class="product_card_footer">
            <div class="product_name">
              <h4>${product.product_name}</h4>
            </div>
            ${attributes}
            <div class="__product_price">${product.price} Ft</div>
          </div>
        </div>
      `;
      });

      $("#related_slider").html(`${relatedProducts}`);
    }
  }

  setPrimaryAttrProduct(products) {
    let values = [];
    let primaryAttrData = [];

    if (!this.state.primaryAttr) {
      return;
    }

    products.forEach((product, index) => {
      product?.attributes.forEach((attributes, key) => {
        if (
          attributes.id === this.state.primaryAttr?.id &&
          !values.includes(attributes.value)
        ) {
          values.push(attributes.value);

          primaryAttrData.push({
            id: attributes.id_attribute,
            value: attributes.value,
            name: attributes.name,
            img: product.img,
          });
        }
      });
    });

    this.renderPrimaryAttrSelect(primaryAttrData);
  }

  renderPrimaryAttrSelect(data) {
    let targetDiv = $("#primary-attr-select");
    let HTML = "";

    data?.forEach((item, index) => {
      HTML += `
        <div class="img_holder ${
          this.state.primaryAttr.value === item.value ? "active" : ""
        }" data-value="${item.value}" data-name="${item.name}">
          <img src="/public/uploads/${item.img}" alt="${
        item.value
      }" width="80px" height="80px">
        </div>
      `;
    });

    targetDiv.html(HTML);

    $("#primary-attr-select .img_holder").on("click", (e) => {
      let clickedDiv = $(e.target).closest(".img_holder");
      let value = clickedDiv.data("value");

      if (this.state.primaryAttr.value == value) {
        return;
      }

      let inputName = `product_${clickedDiv.data("name")}`;
      let targetInput = $(`#${inputName}`).val(value).change();
      $(".img_holder").removeClass("active");
      clickedDiv.addClass("active");
    });
  }

  setMoreProductsSlider(products) {
    if (products.length < 1) {
      return;
    }

    $(document).ready(function () {
      $(".groupped_products_slider").slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow:
          '<div class="slider_arrow prev"><i class="fas fa-chevron-left"></i></div>',
        nextArrow:
          '<div class="slider_arrow next"><i class="fas fa-chevron-right"></i></div>',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 798,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 489,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    });

    $(".groupped_products_title").html("<h3>Még több termék</h3>");

    $(".groupped_products_slider").html("");
    let HTML = "";

    products?.forEach((product) => {
      let attributes = ``;

      for (let i = 0; i < product?.attributes?.length; i++) {
        if (i == 2) {
          break;
        }
        attributes += `
        <div class="attributes_item">${product.attributes[i].display}: ${product.attributes[i].value}</div>
      `;
      }

      let img = `<img src="/public/uploads/${product.img}" alt="${product.name}">`;

      if (parseInt(product.count) > 1 && product?.group_img) {
        img = `<img src="/public/uploads/${product.group_img}" alt="${product.name}">`;
      }

      HTML += `
      <div class="product_card">
        <a class="product_card_link" href="${SITE_URL}${this.state.webshopUrl}/product/${
        product.slug
      }"></a>
        <div class="product_card_body">
          ${img}
          <a href="${SITE_URL}${this.state.webshopUrl}/product/${
        product.slug
      }"><div class="view_panel">Megtekintés</div></a>
        </div>
        <div class="product_card_footer">
          <div class="product_name">
          <h4>${
            parseInt(product.count) > 1 ? product.group_name : product.name
          }</h4>
          </div>
          ${attributes}
          <div class="__product_price">${product.price} Ft</div>
        </div>
    `;

      if (product.in_stock <= 0 && product.is_preorder == false) {
        HTML += `
      <div class="sold_out">
        <span>Elfogyott</span>
      </div>
      `;
      }

      if (parseInt(product.count) > 1) {
        HTML += `
      <div class="more_products">
        <span>+${parseInt(product.count) - 1}</span>
        <i class="fas fa-layer-group"></i>
      </div>
      `;
      }

      HTML += `</div>`;
    });

    $("#groupped_slider").html(`${HTML}`);
  }

  getProductId() {
    let id = $(".content").data("productid");
    return id;
  }

  addListeners() {
    $("#product_quantity").on("change", (e) => {
      if (!e.target.value || e.target.value < 1) {
        $("#product_quantity").val(1);
      } else if (e.target.value > 5) {
        $("#product_quantity").val(5);
      }
      let price = new Intl.NumberFormat("hu-HU", {
        style: "currency",
        currency: "HUF",
        minimumFractionDigits: 0,
      }).format(e.target.value * this.state.product.price);
      $("#product-price").html(`${price}`);

      this.checkInventory(e.target.value);
    });

    $("#cart-btn").on("click", (e) => {
      if (parseInt($("#product_quantity").val()) > 5) {
        window.alert("A mennyiség nem lehet több 5db -nál!");
        return;
      }
      if (this.state.isError === false) {
        this.setState({ ...this.state, in_cart: !this.state.in_cart });

        let purchase = {
          type: "shop_product",
          id: this.state.product.id,
          img: this.state.product.img,
          attributes: this.state.product.attributes,
          quantity: parseInt($("#product_quantity").val()),
          name: encodeURIComponent(this.state.product.product_name),
          is_preorder: this.state.product.is_preorder == true,
          size: "general",
        };

        this.renderCartBtn();
        this.emit("purchase", purchase);
      }
    });
  }

  checkInventory(quantity) {
    if (
      parseInt(this.state.product.in_stock) < parseInt(quantity) &&
      this.state.product.is_preorder != true &&
      this.state.product.is_active == true &&
      this.state.isError === false
    ) {
      this.setState({ ...this.state, in_stock: false });
      $("#error_quantity").html(
        "A kiválasztott mennyiség nincs raktárkészleten"
      );
      if (!$("#cart-btn").hasClass("disabled")) {
        $("#cart-btn").addClass("disabled");
      }
    } else {
      $("#error_quantity").html("");
      this.setState({ ...this.state, in_stock: true });
      this.renderCartBtn();
    }
  }

  renderError(response) {
    this.setState({ ...this.state, isError: true });

    $("#parameter-error").html(response.error);
    if (!$("#cart-btn").hasClass("disabled")) {
      $("#cart-btn").addClass("disabled");
    }
  }

  renderError404() {
    $("#product_container").html(`
      <h1>Ez a termék nem létezik!</h1>
    `);
  }
}

export default ShopProductView;
