import ViewComponent from "../../../ViewComponent";

class CategorySettingsView extends ViewComponent {
  constructor(model) {
    super();
    this._model = model;
    this.state = {
      data: null,
    };

    this._model.getCategories();
    this.addListeners();
  }

  render(results) {
    this.setState({ data: results });
    $("#shop_category_list").html("");

    $("#shop_category_list").append(`
    <div class="category_list_header">
      <div class="header_item">
          #Azonosító
      </div>
      <div class="header_item">
          Megnevezés
      </div>
      <div class="header_item">
      </div>
    </div>
    `);

    results.forEach((category) => {
      $("#shop_category_list").append(`
      <div class="category_list_row" data-id=${category.id}>
        <div class="table_list_item">
            #${category.id}
        </div>
        <div class="table_list_item">
            ${category.name}
        </div>
        <div class="table_list_item">
            <div class="list_action_btns">
                <span class="shop_edit_btn list_action_btn text-warning"><i class="fas fa-edit"></i></span>
                <span class="shop_delete_btn list_action_btn text-danger"><i class="fas fa-trash-alt"></i></span>
            </div>
        </div>
      </div>
      `);
    });

    $(".shop_edit_btn").on("click", (e) => {
      let id = $(e.target).closest("[data-id]").data("id");

      this.editModal(id);
    });

    $(".shop_delete_btn").on("click", (e) => {
      let id = $(e.target).closest("[data-id]").data("id");

      this.deleteCategory(id);
    });
  }

  addListeners() {
    $("#add_new_btn").on("click", () => {
      this.openNewModal();
    });

    $("#save-category").on("click", () => {
      this.saveForm();
    });
  }

  saveForm() {
    let data = {
      category_name: $("#category_name").val(),
    };
    if (this.validateForm(data)) {
      let id = $("#category_id").val();
      if (id !== "") {
        data.id = id;
      }
      this._model.saveCategory(data);
    }
  }

  validateForm(data) {
    let isValid = true;
    $("#error_name").html("");

    if (data.category_name === "") {
      $("#error_name").html("<p>A mező kitöltése kötelező</p>");
      isValid = false;
    }

    return isValid;
  }

  openNewModal() {
    $("#category_modal").modal("show");
    $("#category_name").val("");
    $("#category_id").val("");
    $("#error_name").html("");
  }

  editModal(id) {
    $("#error_name").html("");
    let category = this.state.data.find((category) => category.id == id);
    $("#category_modal").modal("show");
    $("#category_name").val(category.name);
    $("#category_id").val(category.id);
  }

  deleteCategory(id) {
    if (confirm("Biztosan törölni szeretné?") == true) {
      this._model.deleteCategory(id);
    }
  }

  successSave() {
    $("#category_modal").modal("hide");
    this._model.getCategories();
  }

  successDelete() {
    this._model.getCategories();
  }

  setErrors(errors) {
    if (errors.name_error) {
      $("#error_name").html(errors.name_error);
    }
  }
}

export default CategorySettingsView;
