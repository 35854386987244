import Model from "./ProductSettingsModel";
import View from "./ProductSettingsView";
import PageController from "../../../PageController";

class Controller extends PageController {
  constructor(model, view) {
    super();
    this._model = model;
    this._view = view;

    this._model.on("gotProducts", (results) => {
      this._view.render(results);
      this._view.renderPagination(results?.current_page, results?.pages);
    });

    this._model.on("gotGroups", (results) => {
      this._view.setGroups(results);
    });

    this._model.on("gotAttributes", (results) => {
      this._view.renderAttributes(results);
    });

    this._model.on("productDeleted", (results) => {
      this._view.successDelete();
    });

    this._model.on("productSaved", (results) => {
      this._view.successSave();
    });

    this._model.on("setErrors", (results) => {
      this._view.setErrors(results);
    });
  }
}

const ProductSettings = (function () {
  return {
    init: () => {
      const model = new Model();
      const view = new View(model);
      const controller = new Controller(model, view);
    },
  };
})();

export default ProductSettings;
