import Model from "./AttributeSettingsModel";
import View from "./AttributeSettingsView";
import PageController from "../../../PageController";

class Controller extends PageController {
  constructor(model, view) {
    super();
    this._model = model;
    this._view = view;

    this._model.on("gotAttributes", (results) => {
      this._view.render(results);
    });

    this._model.on("setErrors", (errors) => {
      this._view.setErrors(errors);
    });

    this._model.on("attributeSaved", (response) => {
      this._view.successSave();
    });

    this._model.on("attributeDeleted", (response) => {
      this._view.successDelete();
    });
  }
}

const AttributeSettings = (function () {
  return {
    init: () => {
      const model = new Model();
      const view = new View(model);
      const controller = new Controller(model, view);
    },
  };
})();

export default AttributeSettings;
