import ModelComponent from "../../../ModelComponent";

class ProductGroupSettingsModel extends ModelComponent {
  constructor() {
    super();
  }

  getProductGroups() {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_all_product_groups",
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotProductGroups", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getAttributes() {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_attributes",
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotAttributes", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  deleteGroup(id) {
    $.ajax({
      method: "POST",
      url: SITE_URL + "api/webshop/v1/delete_group",
      data: {
        id: id,
      },
      success: (response) => {
        response = JSON.parse(response);

        this.emit("groupDeleted", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getGroupAttributes(id) {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_group_attributes?id=" + id,
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotGroupAttributes", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  saveProductGroup(data) {
    $.ajax({
      method: "POST",
      url: SITE_URL + "api/webshop/v1/new_product_group",
      data: data,
      success: (response) => {
        response = JSON.parse(response);
        if (response.status === 422) {
          this.emit("setErrors", response.errors);
        }

        this.emit("groupSaved", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}

export default ProductGroupSettingsModel;
