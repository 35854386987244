import ViewComponent from "../../../ViewComponent";

class AttributeSettingsView extends ViewComponent {
  constructor(model) {
    super();
    this._model = model;
    this.state = {
      data: null,
      errors: null,
    };

    this._model.getAttributes();
    this.addListeners();
  }

  render(results) {
    this.setState({ data: results });
    $("#shop_attribute_list").html("");

    $("#shop_attribute_list").append(`
    <div class="attribute_list_header">
      <div class="header_item">
          #Azonosító
      </div>
      <div class="header_item">
          Név
      </div>
      <div class="header_item">
        Megnevezés
      </div>
      <div class="header_item">
      </div>
    </div>
    `);

    results.forEach((product) => {
      $("#shop_attribute_list").append(`
      <div class="attribute_list_row" data-id=${product.id}>
        <div class="table_list_item">
            #${product.id}
        </div>
        <div class="table_list_item">
            ${product.name}
        </div>
        <div class="table_list_item">
          ${product.display}
        </div>
        <div class="table_list_item">
            <div class="list_action_btns">
                <span class="shop_edit_btn list_action_btn text-warning"><i class="fas fa-edit"></i></span>
                <span class="shop_delete_btn list_action_btn text-danger"><i class="fas fa-trash-alt"></i></span>
            </div>
        </div>
      </div>
      `);
    });

    $(".shop_edit_btn").on("click", (e) => {
      let id = $(e.target).closest("[data-id]").data("id");

      this.editModal(id);
    });

    $(".shop_delete_btn").on("click", (e) => {
      let id = $(e.target).closest("[data-id]").data("id");

      this.deleteAttribute(id);
    });
  }

  addListeners() {
    $("#add_new_btn").on("click", () => {
      this.openNewModal();
    });

    $("#save-attribute").on("click", () => {
      this.saveForm();
    });
  }

  openNewModal() {
    $("#attribute_modal").modal("show");
    $("#attribute_name").val("");
    $("#attribute_id").val("");
    $("#attribute_display").val("");
    $("#error_name").html("");
    $("#error_display").html("");
  }

  saveForm() {
    let data = {
      attribute_name: $("#attribute_name").val(),
      attribute_display: $("#attribute_display").val(),
    };
    if (this.validateForm(data)) {
      let id = $("#attribute_id").val();
      if (id !== "") {
        data.id = id;
      }
      this._model.saveAttributes(data);
    }
  }

  validateForm(data) {
    let isValid = true;
    $("#error_name").html("");
    $("#error_display").html("");

    if (data.attribute_name === "") {
      $("#error_name").html("<p>A mező kitöltése kötelező</p>");
      isValid = false;
    }

    if (data.attribute_display === "") {
      $("#error_display").html("<p>A mező kitöltése kötelező</p>");
      isValid = false;
    }

    return isValid;
  }

  successSave() {
    $("#attribute_modal").modal("hide");
    this._model.getAttributes();
  }

  setErrors(errors) {
    if (errors.name_error) {
      $("#error_name").html(errors.name_error);
    }

    if (errors.display_error) {
      $("#error_display").html(errors.display_error);
    }
  }

  editModal(id) {
    $("#error_name").html("");
    $("#error_display").html("");
    let attribute = this.state.data.find((attribute) => attribute.id == id);
    $("#attribute_modal").modal("show");
    $("#attribute_name").val(attribute.name);
    $("#attribute_id").val(attribute.id);
    $("#attribute_display").val(attribute.display);
  }

  deleteAttribute(id) {
    if (confirm("Biztosan törölni szeretné?") == true) {
      this._model.deleteAttribute(id);
    }
  }

  successDelete() {
    this._model.getAttributes();
  }
}

export default AttributeSettingsView;
