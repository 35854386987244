import ViewComponent from "../../../ViewComponent";
import "slick-carousel";

require("slick-carousel/slick/slick.css");

class ShopView extends ViewComponent {
  constructor(model) {
    super();
    this._model = model;
    this.state = {
      showRecommended: true,
      activeNav: "recommended",
      category: null,
      search: null,
      isGroupped: true,
      data: null,
      filters: [],
      sortType: "default",
      recommendedProducts: [],
      webshopUrl: "tvmacibolt",
    };

    this._model.getGroupedProducts();
    this._model.getProductGroups();
    const urlParams = new URLSearchParams(window.location.search);
    const categoryParam = urlParams.get('category');

    if (!categoryParam) {
      this._model.getRecommendedProducts();
    } else if (categoryParam === "all") {
      this.state.showRecommended = false;
      $("#shop_list_title").addClass("d-none");
    }
    this.addListeners();
  }

  render(results = null) {
    if (results) {
      this.setState({ ...this.state, data: results });
    } else {
      results = this.state.data;
    }

    $("#product_container").html("");
    let HTML = "";

    results?.forEach((product) => {
      let attributes = ``;

      for (let i = 0; i < product?.attributes?.length; i++) {
        if (i == 2) {
          break;
        }
        attributes += `
          <div class="attributes_item">${product.attributes[i].display}: ${product.attributes[i].value}</div>
        `;
      }

      let img = `<img src="/public/uploads/${product.img}" alt="${product.name}">`;

      if (
        parseInt(product.count) > 1 &&
        this.state.isGroupped === true &&
        product?.group_img
      ) {
        img = `<img src="/public/uploads/${product.group_img}" alt="${product.name}">`;
      }

      HTML = `
      <div class="product_card stacked ${product.is_hidden == true ? 'hidden_product_group' : ''}" data-slug="${product.slug}">
        <a class="product_card_link" href="${SITE_URL}${this.state.webshopUrl}/product/${
        product.slug
      }"></a>
        ${
          product.is_active == false
            ? `
          <div class="ribbon_tag right">
            <span>Előnézet</span>
          </div>
        `
            : ""
        }
        ${
          product.is_preorder == true
            ? `
        <div class="ribbon_tag preorder left">
          <span>Előrendelhető</span>
        </div>
      `
            : ""
        }
        <div class="product_card_body">
          ${img}

        <div class="view_panel">
          ${this.renderViewPanelSection(product)}
        </div>
        </div>
        <div class="product_card_footer">
          <div class="product_name">
            <h4>${
              parseInt(product.count) > 1 && this.state.isGroupped === true
                ? product.group_name
                : product.name
            }</h4>
          </div>
      `;

      if (parseInt(product.count) > 1 && this.state.isGroupped === true) {
        HTML += `
        <div class="__product_price">${this.formatCurrency(product.min_price)} -tól</div>
      </div>
        `;
      } else {
        HTML += `
        ${attributes}
        <div class="__product_price">${this.formatCurrency(product.price)}</div>
      </div>
        `;
      }

      if (product.in_stock <= 0 && product.is_preorder == false) {
        HTML += `
        <div class="sold_out">
          <span>Elfogyott</span>
        </div>
        `;
      }

      if (parseInt(product.count) > 1 && this.state.isGroupped === true) {
        HTML += `
        <div class="more_products">
          <span>+${parseInt(product.count) - 1}</span>
          <i class="fas fa-layer-group"></i>
        </div>
        <div class="stacked_card first"></div>
        <div class="stacked_card second"></div>
        `;
      }

      HTML += `</div>`;

      $("#product_container").append(`
        ${HTML}
      `);
    });

    $("#product_container .add_to_cart_btn").on("click", (e) => {
      let slug = $(e.target).closest(".product_card").data("slug");
      let product = this.state.data.find((product) => product.slug === slug);

      this.handleCartBtnClick("product", product);
    });
  }

  handleCartBtnClick(type, product) {
    let purchase = {
      type: "shop_product",
      id: product.id,
      img: product.img,
      attributes: product.attributes,
      quantity: 1,
      name: type === "product" ? encodeURIComponent(product.name) : encodeURIComponent(product.product_name),
      is_preorder: product.is_preorder == true,
      size: "general",
    };

    this.emit("purchase", purchase);
  }

  renderViewPanelSection(product) {
    let HTML = `<a class="view_panel_link" href="/${this.state.webshopUrl}/product/${
      product.slug
    }"><span class="view_panel_text ${
      product?.in_cart ? "color-in-cart" : "color-default"
    }">Megtekintés</span></a>
      ${
        (parseInt(product.count) > 1 && this.state.isGroupped === true) ||
        parseInt(product.in_stock) == 0
          ? ""
          : `
          <span class="add_to_cart_btn ${
            product?.in_cart ? "bg-color-in-cart" : "bg-color-default"
          }">
          <span class="cart_btn_icon">
        ${
          product?.in_cart
            ? "<i class='fas fa-minus plus_icon'></i>"
            : "<i class='fas fa-plus plus_icon'></i>"
        }
          </span>
          <i class="fas fa-shopping-cart"></i>
        </span>
        `
      }`;

    return HTML;
  }

  renderCartBtn(in_cart, product) {
    let viewPanel = $(`.product_card[data-slug=${product.slug}]`).find(
      ".view_panel"
    );

    let viewPanelText = $(viewPanel).find(".view_panel_text");
    let cartIcon = $(viewPanel).find(".cart_btn_icon");
    let addCartBtn = $(viewPanel).find(".add_to_cart_btn");

    if (in_cart) {
      $(viewPanelText).removeClass("color-default");
      $(viewPanelText).addClass("color-in-cart");

      $(addCartBtn).removeClass("bg-color-default");
      $(addCartBtn).addClass("bg-color-in-cart");

      $(cartIcon).html(`<i class='fas fa-minus plus_icon'></i>`);
    } else {
      $(viewPanelText).removeClass("color-in-cart");
      $(viewPanelText).addClass("color-default");

      $(addCartBtn).removeClass("bg-color-in-cart");
      $(addCartBtn).addClass("bg-color-default");

      $(cartIcon).html(`<i class='fas fa-plus plus_icon'></i>`);
    }
  }

  checkProductAvailability(productId) {
    let available = false;

    this._model
      .checkProductAvailability(productId)
      .then((result) => {
        if (result.data.available === true) available = true;
        return available;
      })
      .catch((err) => {
        console.log(err);
      });

    return available;
  }

  reRenderCartBtn() {
    let products = this.state.data.concat(this.state.recommendedProducts);
    products = [
      ...new Set([...this.state.data, ...this.state.recommendedProducts]),
    ];

    products.forEach((product) => {
      this.emit("cartCheck", product);
    });
  }

  renderFilters(results, filterType = null) {
    let HTML = "";
    if (filterType === "product_groups") {
      HTML += `
        <div class="sidebar_item">
          <div class="sidebar_header base">
            <div>
              <span class="sidebar_title">Termék csoport</span>
            </div>
          </div>
          <div class="sidebar_options">
      `;
      results?.map((group) => {
        HTML += `
            <div class="options_item">
              <div class="form-check filter_checkbox">
                <input class="form-check-input filters" type="checkbox" value="" id="group_${group.id}" data-type="${filterType}">
                <label class="form-check-label" for="group_${group.id}">
                  ${group.group_name}
                </label>
              </div>
            </div>
        `;
      });
      HTML += `
        </div>
      </div>
      `;

      if (results?.length > 0) $("#filter_container").append(HTML);

      $("#close_filters_btn").on("click", (e) => {
        $(".filter_wrapper").removeClass("open");
        $(".filter_backdrop").removeClass("open");
      });

      $("#short_filter_btn").on("click", (e) => {
        this.handleFilters();
      });

      $("input.filters").on("change", (e) => {
        this.handleFilters();
      });
    }
  }

  handleSearch() {
    this._model.search(
      this.state.isGroupped,
      this.state.search,
      this.state.category,
      this.state.filters,
      this.state.sortType
    );
  }

  handleFilters() {
    let filters = {
      product_groups: [],
    };

    let filterInputs = $("input.filters");
    filterInputs?.map((key, input) => {
      let id = $(input).attr("id");
      let checked = $(input).prop("checked");
      let type = $(input).data("type");

      if (checked === true && type === "product_groups") {
        filters["product_groups"].push(id.split("_")[1]);
      }
    });

    this.state.sortType = $("#sort_type").val();

    this.state.filters = filters;

    this.handleSearch();
  }

  setRecommendedProducts(products) {
    if (products.length) {
      this.state.recommendedProducts = products;
      this.renderRecommendedProducts();
    }
  }

  renderRecommendedProducts() {
    if (this.state.showRecommended === false) {
      $("#shop_list_title").addClass("d-none");
    }

    let slider = $(".recommended_product_slider");

    if (slider.hasClass("slick-initialized")) {
      slider[0].slick.unslick();
    }
    $("#recommended_product_container").removeClass("d-none");

    $(document).ready(function () {
      slider.slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow:
          '<div class="slider_arrow prev"><i class="fas fa-chevron-left"></i></div>',
        nextArrow:
          '<div class="slider_arrow next"><i class="fas fa-chevron-right"></i></div>',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 798,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 489,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    });

    if (this.state.recommendedProducts.length > 0) {
      let title = this.state?.isGroupped ? "Termékcsoportok" : "Termékek";
      $("#shop_list_title").html(`<h1 class="mt-3">${title}</h1>`);
    }

    let HTML = "";
    this.state.recommendedProducts?.forEach((product) => {
      let attributes = ``;

      for (let i = 0; i < product?.attributes?.length; i++) {
        if (i == 2) {
          break;
        }
        attributes += `
          <div class="attributes_item">${product.attributes[i].display}: ${product.attributes[i].value}</div>
        `;
      }

      HTML += `
      <div class="product_card recommended_product" data-slug="${product.slug}">
        <a class="product_card_link" href="${SITE_URL}${this.state.webshopUrl}/product/${
        product.slug
      }"></a>
        ${
          product.is_active == false
            ? `
          <div class="ribbon_tag right">
            <span>Előnézet</span>
          </div>
        `
            : ""
        }
        ${
          product.is_preorder == true
            ? `
        <div class="ribbon_tag preorder left">
          <span>Előrendelhető</span>
        </div>
      `
            : ""
        }
        <div class="product_card_body">
          <img src="/public/uploads/${product.img}" alt="">

          <div class="view_panel">
            ${this.renderViewPanelSection(product)}
          </div>
        </div>
        <div class="product_card_footer">
          <div class="product_name">
            <h4>${product.product_name}</h4>
          </div>
          ${attributes}
          <div class="__product_price">${this.formatCurrency(
            product.price
          )}</div>
        </div>
      `;

      HTML += `</div>`;
    });

    $("#recommended_product_container").html(`${HTML}`);

    slider.on("breakpoint", () => {
      $("#recommended_product_container .add_to_cart_btn").off("click");
      this.addRecommendedProductCartClickEvent();
    });

    slider.on("init", () => {
      this.addRecommendedProductCartClickEvent();
    });
  }

  addRecommendedProductCartClickEvent() {
    $("#recommended_product_container .add_to_cart_btn").on("click", (e) => {
      let slug = $(e.target).closest(".product_card").data("slug");
      let product = this.state.recommendedProducts.find(
        (product) => product.slug === slug
      );

      this.handleCartBtnClick("recommended_product", product);
    });
  }

  changeNav(name) {
    if (name === this.state.activeNav) {
      return;
    }

    this.state.activeNav = name;

    $(".webshop_nav").removeClass("active");
    $(`.webshop_nav[data-type="${name}"]`).addClass("active");

    switch (name) {
      case "all":
        $("#recommended_product_container").addClass("d-none");
        $("#shop_list_title").addClass("d-none");
        break;
      case "recommended":
        this.renderRecommendedProducts();
        $("#shop_list_title").removeClass("d-none");
        break;
    }
    // $("#recommended_product_container").toggleClass("d-none");
  }

  addListeners() {
    $("#product-search").keyup((e) => {
      let value = e.target.value;

      if (value.length >= 3 || value.length === 0) {
        this.setState({ ...this.state, search: value });
      }
    });

    $("#category_all").on("click", (e) => {
      this.setActiveCategory(e);
    });

    $("#category_physical").on("click", (e) => {
      this.setActiveCategory(e, "Fizikai termék");
    });

    $("#category_virtual").on("click", (e) => {
      this.setActiveCategory(e, "Virtuális termék");
    });

    $("#category_voucher").on("click", (e) => {
      this.setActiveCategory(e, "Voucher termék");
    });

    $("#show_groupped").on("click", (e) => {
      if (this.state.isGroupped === true) return;

      $(".group_btn").removeClass("active");
      $(e.target).closest(".group_btn").addClass("active");

      this.setState({ ...this.state, isGroupped: true });
      let value = $("#show_groupped a").html();
      $("#view_select").html(value);
      $("#shop_list_title").html(`<h1 class="mt-3">Termékcsoportok</h1>`);
      this.handleSearch();
    });

    $("#show_all").on("click", (e) => {
      if (this.state.isGroupped === false) return;

      $(".group_btn").removeClass("active");
      $(e.target).closest(".group_btn").addClass("active");

      this.setState({ ...this.state, isGroupped: false });
      let value = $("#show_all a").html();
      $("#view_select").html(value);
      $("#shop_list_title").html(`<h1 class="mt-3">Termékek</h1>`);
      this.handleSearch();
    });

    $("#open_filters_btn").on("click", (e) => {
      $(".filter_wrapper").addClass("open");
      $(".filter_backdrop").addClass("open");
    });

    $("#search_form").submit((e) => {
      e.preventDefault();

      this.handleSearch();
    });

    $("#clear_search").on("click", (e) => {
      $("#product-search").val("");
      this.state.search = "";

      this.handleSearch();
    });

    $("#sort_type").on("change", (e) => {
      this.handleFilters();
    });

    $(".webshop_nav").on("click", (e) => {
      let name = $(e.target).closest(".webshop_nav").data("type");
      this.changeNav(name);
    });
  }

  setActiveCategory(element, category = "") {
    if ($(element.target).hasClass("active")) {
      return;
    }

    this.setState({ ...this.state, category: category });

    $(".category_btn").removeClass("active");
    $(element.target).addClass("active");

    this.handleSearch();
  }

  handleErrorMsg(msg) {
    alert(msg);
  }
  
  formatCurrency(value) {
    let currency = new Intl.NumberFormat("hu-HU", {
      style: "currency",
      currency: "HUF",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);

    return currency;
  }
}

export default ShopView;
