import Component from '../../Component';
import Model from './CartModel';
import View from './CartView';

class Controller {
  constructor(model, view) {
    this._model = model;
    this._view = view;
    
    this.cart = this._model.getCart();
    
    this._model.on('ready', newCart => {
      this._view.updateCart(newCart)
    }) 

    this._model.on('switched', newCart => {
      this._view.updateCart(newCart);
    })

    this._model.on('addedToCart', newCart => {
      this._view.updateCart(newCart);
    })

    this._model.on('removedFromCart', newCart => {
      this._view.updateCart(newCart);
    })
  }

  init(){
    if (this.cart.length) {
      this._model.getPrices(this.cart, newCart => {
        this._model.emit('ready', newCart)
      });
    } else {
      this._view.updateCart(this.cart)
      this._model.emit('ready', this.cart)
    }

    setInterval(() => {
      let current = this._model.getCart();
      if (current.length !== this.cart.length) {
        this.cart = current;
        this._view.updateCart(this.cart);
      }
    }, 1000);
  }
}

class Cart extends Component {
  constructor() {
    super();
    this._model = new Model();
    this._view = new View(this._model);
    this._controller = new Controller(this._model, this._view);
    /*
    this._model.on('addedToCart', newCart => {
      this.emit('addedToCart', newCart);
    })*/

    this._view.on('update', newCart => {
      this.emit('update', newCart);
    })

    this._model.on('ready', newCart => {
      this.emit('ready', newCart);
    })

    /*
    this._model.on('removedFromCart', newCart => {
      this.emit('removedFromCart', newCart);
    })*/
  }

  get() {
    return this._model.getCart();
  }

  add(item = {}) {
    return this._model.addToCart(item);
  }
  
  remove(id) {
    return this._model.removeFromCart(id);
  }
  
  switch(id, new_item) {
    return this._model.switch(id, new_item);
  }

  idInCart(id) {
    return this._model.idInCart(id);
  }

  itemInCart(item) {
    return this._model.itemInCart(item);
  }

  init(){
    this._controller.init();
  }
}

export default Cart;