import ViewComponent from "../../../ViewComponent";
import Pagination from "../../../pagination/Pagination";

class ProductSettingsView extends ViewComponent {
  constructor(model) {
    super();
    this._model = model;
    this.state = {
      groups: null,
      products: null,
      attributes: null,
    };

    this._model.getProducts(1);

    this.pagination = new Pagination(5);

    this.addListeners();
  }

  render(results) {
    this.setState({ ...this.state, products: results?.data });
    this.pagination.setPage(results?.current_page);
    this.pagination.setTotalPages(results?.pages);

    $("#product_list").html("");

    results?.data?.forEach((product) => {
      $("#product_list").append(`
      <tr data-id=${product.id} ${product.is_hidden == true ? "class='hidden_product'" : "" }>
        <td><img src="/public/uploads/${
          product.img
        }" width="50" height="50" alt=""></td>
        <td>${product.id}</td>
        <td>${product.category_name}</td>
        <td>${product.group_name}</td>
        <td class="limit_text">
          ${product.is_recommended == true ? `<span class="recommended_icon"><i class="fas fa-star"></i></span>` : ``}
          ${product.product_name}
        </td>
        <td>${product.in_stock}</td>
        <td>${product.price} Ft</td>
        <td>
          <div class="active_badge ${
            product?.is_active == true ? "active" : ""
          }"></div>
        </td>
        <td>
          <div class="active_badge ${
            product?.is_preorder == true ? "active" : ""
          }"></div>
        </td>
        <td>
          <div class="active_badge ${
            product?.is_consignment == true ? "active" : ""
          }"></div>
        </td>
        <td>
          <div class="table_action_btns">
            <div class="btn-group">
              <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ul class="dropdown-menu dropdown-menu-right">
                <li class="dropdown-item shop_edit_btn"><span class="action_btn_item"><span>Termék</span><i class="fas fa-edit"></i></span></li>
                <li class="dropdown-item"><a href="${SITE_URL}webshop/edit-group/${
        product.group_id
      }" class="action_btn_item"><span>Termékcsoport</span><i class="fas fa-edit"></i></a></li>
                <li role="separator" class="divider"></li>
                <li class="dropdown-item shop_delete_btn"><span class="action_btn_item"><span>Termék törlése</span><i class="fas fa-trash text-danger"></i></span></li>
              </ul>
            </div>
          </div>
        </td>
      </tr> 
      `);
    });

    $(".shop_edit_btn").on("click", (e) => {
      let id = $(e.target).closest("[data-id]").data("id");

      this.editModal(id);
    });

    $(".shop_delete_btn").on("click", (e) => {
      let id = $(e.target).closest("[data-id]").data("id");

      this.deleteProduct(id);
    });
  }

  renderPagination(currentPage, totalPages) {
    this.pagination.renderPagination(currentPage, totalPages, "#pagination_container");
  }

  renderAttributes(attributes) {
    if (attributes) {
      this.setState({ ...this.state, attributes: attributes });

      $("#attribute-inputs").html("");

      attributes.forEach((attribute) => {
        let HTML = `
        <div class="row mt-1">
          <div class="col-sm-12">
            <label for="product_${attribute.attribute_name}" class="form-label">${attribute.display}</label>
            <input type="text" value="${attribute.value}" class="form-control" id="product_${attribute.attribute_name}" aria-describedby="product_${attribute.attribute_name}" name="product_${attribute.attribute_name}">
            <div class="invalid-feedback text-danger" id="error_product_${attribute.attribute_name}">

            </div>
          </div>
        </div>
        `;

        $("#attribute-inputs").append(HTML);
      });
    }
  }

  addListeners() {
    $("#save-product").on("click", () => {
      this.saveForm();
    });

    const productSearch = $("#product-search");
    const handleSearch = () => {
      if (productSearch.val().length >= 3 || productSearch.val().length == 0) {
        this.pagination.setPage(1)
        this._model.getProducts(this.pagination.getPage(), productSearch.val())
      }
    };
    const debouncedSearch = this.debounce(handleSearch, 600);

    $(productSearch).on("keyup", debouncedSearch);

    this.pagination.setEventHandler((page) => {
      const productSearch = $("#product-search").val();
      this._model.getProducts(page, productSearch);
    });
  }

  debounce(func, wait) {
    let timeout;
    return function(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  editModal(id) {
    this.clearErrors();
    $("#product_modal").modal("show");
    this._model.getProductAttributes(id);
    let product = this.state.products.find((product) => product.id == id);

    $("#product_id").val(id);
    $("#product_name").val(product.product_name);
    $("#product_description").val(product.product_description);
    $("#product_price").val(product.price);
    $("#in_stock").val(product.in_stock);
    $("#is_active").prop("checked", product.is_active == true);
    $("#is_consignment").prop("checked", product.is_consignment == true);
    $("#is_preorder").prop("checked", product.is_preorder == true);
    $("#is_recommended").prop("checked", product.is_recommended == true);
    $("#inventory_code").val(product.inventory_code);
    $("#is_print").prop("checked", product.is_print == true);
    $("#is_hidden").prop("checked", product.is_hidden == true);
  }

  saveForm() {
    this.clearErrors();

    let data = {
      product_id: $("#product_id").val(),
      product_name: $("#product_name").val(),
      product_description: $("#product_description").val(),
      product_price: $("#product_price").val(),
      in_stock: $("#in_stock").val(),
      is_active: $("#is_active").prop("checked"),
      is_consignment: $("#is_consignment").prop("checked"),
      is_preorder: $("#is_preorder").prop("checked"),
      is_recommended: $("#is_recommended").prop("checked"),
      inventory_code: $("#inventory_code").val(),
      is_print: $("#is_print").prop("checked"),
      is_hidden: $("#is_hidden").prop("checked"),
    };

    if (this.state.attributes) {
      this.state.attributes.forEach((attribute) => {
        data["product_" + attribute.attribute_name] = $(
          `#product_${attribute.attribute_name}`
        ).val();
      });
    }

    if (this.validateForm(data)) {
      this._model.saveProduct(data);
    }
  }

  successSave() {
    $("#product_modal").modal("hide");
    this._model.getProducts();
  }

  validateForm(data) {
    let isValid = true;

    const keyExceptions = ['product_description', 'is_active', 'is_consignment', 'is_preorder', 'is_recommended', 'is_print', 'is_hidden'];

    if (data) {
      for (let [key, value] of Object.entries(data)) {
        if (
          (!value || value.length === 0) && !keyExceptions.includes(key)
        ) {
          isValid = false;
          this.renderValidationError(key, "Mező kitöltése kötelező");
        }
      }
    }

    return isValid;
  }

  deleteProduct(id) {
    if (confirm("Biztosan törölni szeretné?") == true) {
      this._model.deleteProduct(id);
    }
  }

  successDelete() {
    this._model.getProducts();
  }

  renderValidationError(idAttribute, error) {
    $(`#error_${idAttribute}`).html(error);
  }

  clearErrors() {
    $('div[id^="error_"]').html("");
  }

  setErrors(data) {
    for (let [key, value] of Object.entries(data)) {
      $(`#${key}`).html(value);
    }
  }
}

export default ProductSettingsView;
