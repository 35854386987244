import Model from "./ShopProductModel";
import View from "./ShopProductView";
import CartController from "../../../CartController";

class Controller extends CartController {
  constructor(model, view) {
    super();
    this._model = model;
    this._view = view;

    this._model.on("gotProduct", (results) => {
      let in_cart = this.cart.idInCart(results?.id);
      this._view.render(results, in_cart);
    });

    this._model.on("gotAttributeOptions", (results) => {
      this._view.renderAttributeOptions(results);
    });

    this._model.on("gotRequriedAttributes", (results) => {
      this._view.setRequiredAttributes(results);
    });

    this._model.on("gotSelectedProduct", (results) => {
      this._view.setSelectedProduct(results);
    });

    this._model.on("gotGroupProducts", (results) => {
      this._view.setGroupProducts(results);
    });

    this._model.on("gotRandomProducts", (results) => {
      this._view.setMoreProductsSlider(results);
    });

    this._model.on("renderError", (results) => {
      this._view.renderError(results);
    });

    this._model.on("renderError404", () => {
      this._view.renderError404();
    });

    this._view.on("purchase", (purchase) => {
      if (this.cart.idInCart(purchase.id)) {
        this.cart.remove(purchase.id);
      } else {
        this.cart.add(purchase);
      }
    });

    this._view.on("cartCheck", (product) => {
      let in_cart = this.cart.idInCart(product.id) ? true : false;
      this._view.renderCartBtn(true, in_cart);
    });

    this.cart.on('update', newCart => {
      let product = this._view.state.product;
      let in_cart = this.cart.idInCart(product.id) ? true : false;
      this._view.renderCartBtn(true, in_cart);
    })
  }
}

const ShopProduct = (function () {
  return {
    init: () => {
      const model = new Model();
      const view = new View(model);
      const controller = new Controller(model, view);
    },
  };
})();

export default ShopProduct;
