import ModelComponent from "../../../ModelComponent";

class ProductSettingsModel extends ModelComponent {
  constructor() {
    super();
  }

  getProducts(page, query) {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_products",
      data: {
        page: page -1,
        query,
      },
      success: (response) => {
        this.emit("gotProducts", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getProductGroups() {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_product_groups",
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotGroups", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getProductAttributes($id) {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_product_attributes?product_id=" + $id,
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotAttributes", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  deleteProduct(id) {
    $.ajax({
      method: "POST",
      url: SITE_URL + "api/webshop/v1/delete_product",
      data: {
        id: id,
      },
      success: (response) => {
        response = JSON.parse(response);

        this.emit("productDeleted", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  saveProduct(data) {
    $.ajax({
      method: "POST",
      url: SITE_URL + "api/webshop/v1/edit_product",
      data: {
        product: data,
      },
      success: (response) => {
        response = JSON.parse(response);
        if (response.status === 422) {
          this.emit("setErrors", response.errors);
        } else {
          this.emit("productSaved", response);
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}

export default ProductSettingsModel;
