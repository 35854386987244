import ModelComponent from "../../../ModelComponent";

class AttributeSettingsModel extends ModelComponent {
  constructor() {
    super();
  }

  getAttributes() {
    $.ajax({
      method: "GET",
      url: SITE_URL + "api/webshop/v1/get_attributes",
      success: (response) => {
        response = JSON.parse(response);
        this.emit("gotAttributes", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  saveAttributes(data) {
    $.ajax({
      method: "POST",
      url: SITE_URL + "api/webshop/v1/new_attribute",
      data: data,
      success: (response) => {
        response = JSON.parse(response);
        if (response.status === 422) {
          this.emit("setErrors", response.errors);
        }

        this.emit("attributeSaved", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  deleteAttribute(id) {
    $.ajax({
      method: "POST",
      url: SITE_URL + "api/webshop/v1/delete_attribute",
      data: {
        id: id,
      },
      success: (response) => {
        response = JSON.parse(response);

        this.emit("attributeDeleted", response);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}

export default AttributeSettingsModel;
