const Accessibility = (function() {
    return {
        init: () => {

            if (document.cookie.includes("visionImpaired=true")) {
                resizeImpair(true);
            }

            $('#impaired').on('click',function (){
                resizeToImpaired();
            })


            function resizeToImpaired() {
                let visionImpaired = null;
                console.log(document.cookie);
                if (document.cookie.includes("visionImpaired=true")) {
                    visionImpaired = false;
                    document.cookie = "visionImpaired=false";
                    location.reload();
                    location.reload(true);
                } else if (document.cookie.includes("visionImpaired=false")) {
                    visionImpaired = true;
                    document.cookie = "visionImpaired=true";
                }
                if (visionImpaired == null) {
                    visionImpaired = true;
                    document.cookie = "visionImpaired=true";
                }
                resizeImpair(visionImpaired);
            }

            function resizeImpair(visionImpaired) {
                let all = document.querySelectorAll('*');
                if (visionImpaired) {
                    for (const one of all) {
                        one.style.fontSize = '44px';
                        one.style.backgroundColor = 'black';
                        one.style.color = 'yellow';
                    }
                }
            }
        }
    }
})()

export default Accessibility;